import { AnyAction } from 'redux';
import {
  CRUD_EXTENSIONMETADATA_LIST_BEGIN,
  CRUD_EXTENSIONMETADATA_LIST_SUCCESS,
  CRUD_EXTENSIONMETADATA_LIST_FAILURE,
  ReduxExtensionMetadataCrudState
} from '../..';


const extensionMetadataCrudInitialState: ReduxExtensionMetadataCrudState = {
  isLoading: true,
  error: null,
};

export default function extensionMetadataCrudReducer(
  state: ReduxExtensionMetadataCrudState = extensionMetadataCrudInitialState,
  action: AnyAction
) {
  switch (action.type) {
    case CRUD_EXTENSIONMETADATA_LIST_BEGIN:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case CRUD_EXTENSIONMETADATA_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case CRUD_EXTENSIONMETADATA_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
}

import { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { IHttpClient, IHttpClientRequest, IPortalConfig } from '@msx/platform-services';
import { IToDo } from '../../models';
import {
  FETCH_TODO_LIST_BEGIN,
  FETCH_TODO_LIST_SUCCESS,
  FETCH_TODO_LIST_FAILURE,
} from '..';

export const fetchToDoListBegin = () => ({
  type: FETCH_TODO_LIST_BEGIN
});

export const fetchToDoListSuccess = (payload: IToDo[]) => ({
  type: FETCH_TODO_LIST_SUCCESS,
  payload: payload
});

export const fetchToDoListError = (error: AxiosError) => ({
  type: FETCH_TODO_LIST_FAILURE,
  payload: error
});

export const fetchToDoList = (httpClient: IHttpClient, appConfig:IPortalConfig) => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchToDoListBegin());
    try {
      const resource = appConfig.apiConfig.resource;
      const url = `${appConfig.apiConfig.baseUrl}TodoList`;
      const request: IHttpClientRequest = {
        resource: resource,
      };
      const response: any = await httpClient.get(url, request);
      dispatch(fetchToDoListSuccess(response.data ? processTodos(response.data) : null));
    } catch (err) {
      dispatch(fetchToDoListError(err));
    }
  }
}

const processTodos = (todos) => {
  todos.forEach((item) => {
    const parts = item.title.split('|');
    item.title = parts[0];
    if (parts.length > 0) {
      item.state = parts[1];
    }
  });
  return todos;
}

import { AxiosError } from 'axios';
import { IHttpClient, IHttpClientRequest, IPortalConfig } from '@msx/platform-services';
import { IReportVisualProperties } from '../../../models/IReportVisualProperties'
import {
    FETCH_DASHBOARD_VISUALS_CONFIG_BEGIN,
    FETCH_DASHBOARD_VISUALS_CONFIG_SUCCESS,
    FETCH_DASHBOARD_VISUALS_CONFIG_FAILURE,
} from '../..';
import { trackPromise } from 'react-promise-tracker';

export const fetchDashboardVisualsConfigBegin = () => ({
    type: FETCH_DASHBOARD_VISUALS_CONFIG_BEGIN
});

export const fetchDashboardVisualsConfigSuccess = (payload: IReportVisualProperties[]) => ({
    type: FETCH_DASHBOARD_VISUALS_CONFIG_SUCCESS,
    payload: payload
});

export const fetchDashboardVisualsConfigError = (error: AxiosError) => ({
    type: FETCH_DASHBOARD_VISUALS_CONFIG_FAILURE,
    payload: error
});


export const fetchDashboardVisualsConfig = (httpClient: IHttpClient, appConfig: IPortalConfig) => {
    return async (dispatch: any) => {
        dispatch(fetchDashboardVisualsConfigBegin());
        try {
            //let data = LoadMockData(); 
            //dispatch(fetchDashboardVisualsConfigSuccess(data));
            const resource = appConfig.registrationConfig.resource;
            const url = `${appConfig.apiConfig.baseUrl}UserPreference`;
            const request: IHttpClientRequest = {
                resource: resource
            };
            const response: any = await trackPromise(httpClient.get(url, request));
            dispatch(fetchDashboardVisualsConfigSuccess(response.data ? ParseResponse(response.data) : null));
        } catch (err) {
            dispatch(fetchDashboardVisualsConfigError(err));
        }

    }
}

function ParseResponse(data: any) {
    let visuals: IReportVisualProperties[] = [];
    if (data) {
        data.forEach(v => {
            let v1: IReportVisualProperties = {
                key: v.Key,
                name: v.Name,
                description: v.Description,
                component: v.Component,
                active: v.Active,
                attributes: getAttrivutes(v)
            };

            visuals.push(v1);
        });
    }
    return visuals;
}

function getAttrivutes(data) {
    let a: any;
    if (data.Attributes) {
        a = {
            type: data.Attributes.Type,
            reportId: data.Attributes.ReportId,
            embedURL: data.Attributes.EmbedURL,
            accessToken: data.Attributes.AccessToken,
            visualName: data.Attributes.VisualName,
            pageName: data.Attributes.PageName,
            visualTitle: data.Attributes.VisualTitle,
            tags: data.Attributes.Tags
        }
    }
    else {
        a = {
            type: "",
            reportId: "",
            embedURL: "",
            accessToken: "",
            visualName: "",
            pageName: "",
            visualTitle: "",
            tags: []
        };
    }
    return a;
}


import React, { useEffect, useContext } from 'react';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { useSelector } from 'react-redux';
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
import { ServiceContext, useUser, ApplicationContext } from '@msx/platform-services';
import { getExtensionsError, getCurrentTheme } from '../../../core/store';
import { TileList, PageError } from '../../../core/components';
import { HTTP_STATUS_NOT_FOUND } from '../../App.types';
import { messages } from './Home.messages';
import { getStyles } from './Home.styles';
import { Stack, ScrollablePane } from '@fluentui/react';
import { HomeContent } from '../../components/sample';
import { _shellStyles } from '../../App.styles';

const getClassNames = classNamesFunction<any, any>();
let classes: any;

interface OwnProps extends InjectedIntlProps {
  //TODO;
}

type HomeProps = OwnProps & InjectedIntlProps;

const HomeComponent: React.FC<HomeProps> = props => {
  const { intl } = props;
  const theme = useSelector(getCurrentTheme);
  const extensionsError = useSelector(getExtensionsError);
  const { httpClient } = useContext(ServiceContext);
  const { appState } = useContext(ApplicationContext)
  const user = useUser();
  useEffect(() => {
    //TODO: 

  }, [httpClient]);

  useEffect(() => {
    if (user)
      messages.pageTile.defaultMessage = "Hello " + `${user.name}, welcome.!`
  }, [user])

  classes = getClassNames(getStyles, theme);

  const renderNoDataComponent = () => {
    return (
      <p>{props.intl.formatMessage(messages.noDataFound)}</p>
    );
  }

  const renderDataLoadingErrorComponent = () => {
    if (extensionsError.response && extensionsError.response.status === HTTP_STATUS_NOT_FOUND) {
      return renderNoDataComponent();
    }
    return <PageError error={extensionsError} />
  }

  const renderBodyComponent = () => {
    if (extensionsError) {
      return renderDataLoadingErrorComponent();
    }
    return (
      <Stack className={classes.tileContainer}>
        <HomeContent />
        <TileList isEdit={true} />
      </Stack>
    );
  }

  const renderMain = (): JSX.Element => {
    //const fullName = user?.name ?? 'username';
    //const welcomeMsg = "Hello " + `${fullName}, welcome.!`
    return (
      <ScrollablePane className={appState.isNavCollapsed ? _shellStyles.scrollablePaneCollapsed : _shellStyles.scrollablePaneExpand}>
        <div className={appState.isNavCollapsed ? _shellStyles.mainPanelCollapsed : _shellStyles.mainPanelExpand}>
          <h1 className={classes.headingMain}>{intl.formatMessage(messages.pageTile)}</h1>
          {/* <h1 className={classes.headingMain}>{welcomeMsg}</h1> */}
          {renderBodyComponent()}
        </div>
      </ScrollablePane>
    )
  }
  return renderMain();
}

export const Home = injectIntl(HomeComponent);

import { IUserProfile } from '../models';
import { AxiosError } from 'axios';
import {
  IAppState,
  IAppTheme,
  IExtensionsRegistration,
  IKeyValueItem
} from '@msx/platform-services'

export interface ReduxCommonState {
  isLoading: string[];
  error: Error;
}

export interface ReduxAppState {
  locale: string;
  currentTheme: IAppTheme;
  currentAppState: IAppState;
  currentGuidedTourRunStatus: boolean;
  inDashboardEditMode: boolean;
  currentTestExtensoins: IKeyValueItem[];
  mockData: boolean;
  isDevLogIn: boolean;
  localeData: IKeyValueItem[];
  pbiAccessToken : ReduxPBITokenState;
}

export interface ReduxExtensionsRegistrationState {
  extensionsRegistration: IExtensionsRegistration;
  isLoading: boolean;
  error: AxiosError;
}

export interface ReduxUserProfileState {
  userProfile: IUserProfile;
  isLoading: boolean;
  error: AxiosError;
}

export interface ReduxPBITokenState{
  token : string;
  expiresBy : Date; 
}

export interface IExtensionsAttributes {
  type : string;
  reportId?: string;
  datasetIds? : string;
  embedURL : string;
  accessToken : string;
  visualName? : string;
  pageName? : string;
  visualTitle : string;
  tags : string[];
}

export const SET_PBI_ACCESS_TOKEN = 'SET_PBI_ACCESS_TOKEN';

export const SET_CURRENT_LOCALE = 'SET_CURRENT_LOCALE';
export const SET_CURRENT_THEME = 'SET_CURRENT_THEME';
export const SET_CURRENT_APP_STATE = 'SET_CURRENT_APP_STATE';
export const SET_IS_MOCK_DATA = 'SET_IS_MOCK_DATA';
export const SET_LOCALE_DATA = 'SET_LOCALE_DATA';
export const SET_CURRENT_TEST_EXTENSIONS = 'SET_CURRENT_TEST_EXTENSIONS';
export const SET_IN_DASHBOARD_EDIT_MODE = "SET_IN_DASHBOARD_EDIT_MODE";
export const SET_IS_DEV_LOG_IN = 'SET_IS_DEV_LOG_IN';

export const FETCH_EXTENSIONS_REGISTRATION_BEGIN = 'FETCH_EXTENSIONS_REGISTRATION_BEGIN';
export const FETCH_EXTENSIONS_REGISTRATION_SUCCESS = 'FETCH_EXTENSIONS_REGISTRATION_SUCCESS';
export const FETCH_EXTENSIONS_REGISTRATION_FAILURE = 'FETCH_EXTENSIONS_REGISTRATION_FAILURE';

export const FETCH_USER_PROFILE_BEGIN = 'FETCH_USER_PROFILE_BEGIN';
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
export const FETCH_USER_PROFILE_FAILURE = 'FETCH_USER_PROFILE_FAILURE';

export const SET_USER_PROFILE_BEGIN = 'SET_USER_PROFILE_BEGIN';
export const SET_USER_PROFILE_SUCCESS = 'SET_USER_PROFILE_SUCCESS';
export const SET_USER_PROFILE_ERROR = 'SET_USER_PROFILE_ERROR';


import React, { useContext, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { InjectedIntlProps } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
import { getStyles } from './Sample.styles'
import { ApplicationContext } from '@msx/platform-services'
import { messages } from './Sample.messages';

interface OwnProps extends InjectedIntlProps {
  //TODO: any
}

type Props = OwnProps & RouteComponentProps;

const getClassNames = classNamesFunction<any, any>();
let classes: any;

const DashboardContentComponent: (React.FC<Props>) = (props) => {
  const { intl } = props;
  const { appState } = useContext(ApplicationContext);
  //const tileExtensions = extensionsRegistrationClient.getExtensionsTiles();

  classes = getClassNames(getStyles, appState.theme);

  useEffect(() => {
    // Placeholder
    // eslint-disable-next-line
  }, []);

  const renderMain = (): JSX.Element => {
    if (appState.appConfig.registrationConfig.active) return null;
    //if (tileExtensions.length > 0) return null;
    return (
      <div className={classes.aboutroot} >
        <p>
          {intl.formatMessage(messages.dashboard1)}
        </p>
        <p>
          {intl.formatMessage(messages.dashboard2)}
        </p>
      </div>
    );
  }

  return renderMain();

}

export const DashboardContent = withRouter(injectIntl(DashboardContentComponent));


import { createSelector } from 'reselect';
import { ReduxExtensionsRegistrationState, IExtensionsAttributes } from '..';
import { ReduxRootState } from '../../../app/store';
import { IExtensionsRegistration, IAppExtension, IAppExtensionTile } from '@msx/platform-services';
import { AxiosError } from 'axios';

const extensionsRegistrationState = (state: ReduxRootState): ReduxExtensionsRegistrationState => state.extensionsRegistration;

export const getExtensionsRegistration = createSelector(
  extensionsRegistrationState,
  (extensionsRegistrationState: ReduxExtensionsRegistrationState): IExtensionsRegistration => extensionsRegistrationState.extensionsRegistration
);

export const getExtensionsLoadingStatus = createSelector(
  extensionsRegistrationState,
  (extensionsRegistrationState: ReduxExtensionsRegistrationState): boolean => extensionsRegistrationState.isLoading
);

export const getExtensionsError = createSelector(
  extensionsRegistrationState,
  (extensionsRegistrationState: ReduxExtensionsRegistrationState): AxiosError => extensionsRegistrationState.error
);

export const getExtnAttributes = createSelector(
  extensionsRegistrationState,
  (extensionsRegistrationState, key) => key,
  (extensionsRegistrationState, key): IExtensionsAttributes => {
    let attributes: IExtensionsAttributes;
    attributes = extensionsRegistrationState.extensionsRegistration.extensions[0].tiles[0]['attributes'] as IExtensionsAttributes;
    return attributes;
  });

export const getReportExtensionTiles = createSelector(
  extensionsRegistrationState,
  (extensionsRegistrationState: ReduxExtensionsRegistrationState): IAppExtensionTile[] => {
    let reportExtn: IAppExtension;
    let reportTiles: IAppExtensionTile[];
    reportExtn = extensionsRegistrationState.extensionsRegistration.extensions.filter(e => e.name === 'TradeReports')[0];
    if (reportExtn) {
      reportTiles = reportExtn.tiles;
    }
    return reportTiles;
  }
);


import { IAppTheme } from "@msx/platform-services";
import {
  IDropdownStyles,
  IStackTokens,
  mergeStyleSets,
} from "office-ui-fabric-react";

export const getStyles = (theme: IAppTheme) => {
  return {
    root: {
      padding: "5px",
      margin: "5px"
    },
  };
};

export const controlClass = mergeStyleSets({
  Embedcontainer: {
    height: '500px',
    width : '58vw',
    marginTop: "10px",
  },
  searchStyles: {
    marginTop: "30px",
  },
  submitStyles: {
    marginTop: "28px",
    marginLeft: "10px",
  },
  gridStyle:{
    height: '520px',
    border: '1px',
    width : '20vw',
    padding: '1px'
  }
});

export const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: '18vw' },
  dropdownItemsWrapper:{maxHeight:'60vh'}
};

export const stackTokens: IStackTokens = { childrenGap: 0};
export const stackTokens2: IStackTokens = { childrenGap: 0, maxWidth : '58vw' };
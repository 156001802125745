
import React, { useEffect, useState, useContext } from 'react';
import { injectIntl } from 'react-intl';
import { IStyle, Link, mergeStyleSets } from 'office-ui-fabric-react';
import { ICommandBarItemProps } from 'office-ui-fabric-react/lib/CommandBar';
import { CoherenceHeader, NotificationListItem, CoherencePanelSize } from '@cseo/controls';
import { ServiceContext, useUser, ApplicationContext } from '@msx/platform-services';
import { messages } from './AppHeader.messages';
import { HeaderProps } from './AppHeader.types';
import { HelpExample, initialNotifications } from '../sample';
import { Settings } from '..'

const AppHeaderComponent: (React.FC<HeaderProps>) = (props) => {
  const { appState } = useContext(ApplicationContext);
  const context = React.useContext(ServiceContext)
  const [newNotifications, setNewNotifications] = useState(3);
  const [notificationItems, setNotificationItems] = useState(initialNotifications);
  const [showDismissAllBool, setShowDismissAllBool] = useState(false);
  const [dismissOpenedPanel, setDismissOpenedPanel] = useState(undefined);
  const user = useUser();
  const { intl, isUserLoggedIn, appName, isAppReady } = props;

  useEffect(() => {
    // Placeholder
    // eslint-disable-next-line
  }, []);

  const AttentionTextStyles = mergeStyleSets({
    attentionText: {
      minWidth: '240px'
    } as IStyle
  });

  const handleOpenNotificationItem = (itemKey: string) => {
    console.log('Open notification ' + itemKey);
  }

  const handleDismissMultipleNotification = (id: string[]): void => {
    setShowDismissAllBool(true);
    console.log('Dismiss notification ' + id);
  }

  const handleUpdateNotificationItem = (itemKey: string, dStatus: NotificationListItem['displayStatus'], rStatus?: NotificationListItem['status']) => {
    let list = [...notificationItems];
    list.forEach((item) => {
      if (item.itemKey === itemKey) {
        item.displayStatus = dStatus;
        if (rStatus) {
          item.status = rStatus;
        }
      }
    });
    setNotificationItems(list);
  }

  const updateNotificationItemDisplayStatus = (): void => {
    // Placeholder for code that updates displayStatus to 'old' for all items listed in the id string array
    console.log('Mark notification(s) as old');

    // Setting to -1 to see badge disappear when notification panel opens
    setNewNotifications(-1);
  }

  const dismissAllNotificationMessage: any = (
    <div>
      <div className={AttentionTextStyles.attentionText}>All notifications have been dismissed</div>
      <Link className={AttentionTextStyles.attentionText} onClick={() => setShowDismissAllBool(false)}>Undo</Link>
    </div>
  );

  const handleSignOut = () => {
    context.authClient.logOut();
  }

  const telemetryHook = {
    trackMetric: function (metric, customProperties?: { [key: string]: unknown }) {
      // Placeholder
    },
    trackException: function (exception) {
      // Placeholder
    },
    trackEvent: function (event, customProperties?: { [key: string]: unknown }) {
      // Placeholder
    },
    trackTrace: function (trace, customProperties?: { [key: string]: unknown }) {
      // Placeholder
    }
  };

  const getHelpBody = () => {
    return <HelpExample isAppReady={isAppReady} />
  }
  const handleDismissPanel = () => {
    setDismissOpenedPanel(true);
  }

  const getSettingsBody = () => {
    return <Settings onDismissPanel={handleDismissPanel} />
  }

  const getNotificationSettings = () => {
    if (!appState.appConfig.notificaitonConfig.active) {
      return null;
    }
    const item = {
      titleText: intl.formatMessage(messages.settingsNotificationsTitle),
      isUndoDismissAllVisible: showDismissAllBool,
      items: notificationItems,
      newNotifications: newNotifications,
      onDismissMultiple: handleDismissMultipleNotification,
      onPanelOpened: updateNotificationItemDisplayStatus,
      onUndoDismissAll: dismissAllNotificationMessage,
      openItem: handleOpenNotificationItem,
      updateItem: handleUpdateNotificationItem,
      telemetryHook: telemetryHook
    };
    return item;
  }

  const getSearchSettings = () => {
    if (!appState.appConfig.globalSearchConfig.active) {
      return null;
    }
    const item = {
      onChange: () => undefined,
      onSearch: (_: string) => undefined,
      onClear: () => undefined,
      disabled: false,
      placeholder: intl.formatMessage(messages.globalSearchPlaceHolderText)
    };
    return item;
  }


  const getFarItems = (): ICommandBarItemProps[] => {
    let items = [
      {
        key: 'chatBot',
        id: 'header-chat-bot',
        text: intl.formatMessage(messages.chatBot),
        ariaLabel: intl.formatMessage(messages.financeSupportBotAriaLabel),
        iconOnly: true,
        iconProps: {
          iconName: 'Robot',
        },
        onClick: () => {
          (window as any).OpenFDA("hostappid=" + appState.appConfig.botConfig.hostAppId);
        }
      },
      {
        key: 'gudedTour',
        id: 'header-guided-tour',
        text: intl.formatMessage(messages.retakeTour),
        ariaLabel: intl.formatMessage(messages.retakeTourAriaLabel),
        iconOnly: true,
        iconProps: {
          iconName: 'ReadingMode',
        },
        onClick: () => {
          console.log('Guided tour button clicked')
        }
      },
      {
        key: 'privacyStatement',
        id: 'header-privacy-statement',
        text: intl.formatMessage(messages.privacyStatement),
        ariaLabel: intl.formatMessage(messages.privacyStatement),
        iconOnly: true,
        iconProps: {
          iconName: 'ReportLock',
        },
        onClick: () => {
          window.open('http://go.microsoft.com/fwlink/?LinkId=518021', '_blank').focus();
        }
      },
      {
        key: 'Support',
        id: 'header-support',
        text: intl.formatMessage(messages.support),
        ariaLabel: intl.formatMessage(messages.support),
        iconOnly: true,
        iconProps: {
          iconName: 'Telemarketer',
        },
        onClick: () => {
          var ICM_Sev = 4;
          if (process.env.REACT_APP_ENV === `production`) {
            ICM_Sev = 3
          }
          window.open(`mailto:ImpExp-DRI@microsoft.com?subject=%23ICM${ICM_Sev} | Critical Support Request from OneTrade Portal`)
        }
      },
      {
        key: 'BusinessSupport',
        id: 'header-businessSupport',
        text: intl.formatMessage(messages.businessSupport),
        ariaLabel: intl.formatMessage(messages.businessSupport),
        iconOnly: true,
        iconProps: {
          iconName: 'Teamwork',
        },
        onClick: () => {
          window.open('https://onetrade.powerappsportals.com/', '_blank').focus();
        }
      }
    ];
    if (!isAppReady || !isUserLoggedIn) {
      const newItems = items.filter(function (obj) {
        return obj.key !== 'chatBot' && obj.key !== 'gudedTour';
      });
      return newItems;
    }
    if (!appState.appConfig.botConfig.active) {
      items = items.filter(function (obj) {
        return obj.key !== 'chatBot';
      });
    }
    if (!appState.appConfig.guidedTourConfig.active) {
      items = items.filter(function (obj) {
        return obj.key !== 'gudedTour';
      });
    }
    return items;
  }

  const renderFullHeader = (): JSX.Element => {
    const fullName = user?.name ?? 'username';
    const emailAddress = user?.email ?? 'useremail';
    return <CoherenceHeader
      headerLabel={'header'}
      appNameSettings={{
        label: appName
      }}
      searchSettings={getSearchSettings()}
      farRightSettings={{
        additionalItems: getFarItems(),
        //notificationsSettings: getNotificationSettings(),
        settingsSettings: {
          titleText: intl.formatMessage(messages.settingsSettingsTitle),
          body: getSettingsBody(),
          telemetryHook: telemetryHook,
          panelSize: CoherencePanelSize.medium,
        },
        // helpSettings: {
        //   titleText: intl.formatMessage(messages.helpSettingsTitle),
        //   backButton: {
        //     text: intl.formatMessage(messages.helpSettingsBackButton),
        //     ariaLabel: intl.formatMessage(messages.helpSettingsBackButtonAriaLabel),
        //     onClick: () => {
        //       console.log('Help Panel Back Button clicked');
        //     }
        //   },
        //   body: getHelpBody(),
        //   telemetryHook: telemetryHook
        // },
        profileSettings: {
          fullName: fullName,
          emailAddress: emailAddress,
          imageUrl: '',
          logOutLink: '#',
          onLogOut: () => handleSignOut(),
          //onRenderFooter: () => { return <a href='https://privacy.microsoft.com/en-us/privacystatement' rel="noopener noreferrer" target="_blank">Privacy Notice</a> },
          //body: Placeholder to customize body
          telemetryHook: telemetryHook
        }
      }}
      dismissOpenedPanel={dismissOpenedPanel}
      onDismissOpenedPanel={() => {
        setDismissOpenedPanel(undefined);
      }}
      telemetryHook={telemetryHook}
    />
  }

  const renderInitialHeader = (): JSX.Element => {
    return <CoherenceHeader
      headerLabel={'header'}
      appNameSettings={{
        label: appName
      }}
      farRightSettings={{
        additionalItems: getFarItems(),
      }}
      telemetryHook={telemetryHook}
    />
  }

  const renderNotLoggedInHeader = (): JSX.Element => {
    return <CoherenceHeader
      headerLabel={'header'}
      appNameSettings={{
        label: appName
      }}
      farRightSettings={{
        additionalItems: getFarItems(),
        settingsSettings: {
          titleText: intl.formatMessage(messages.settingsSettingsTitle),
          body: getSettingsBody(),
          telemetryHook: telemetryHook
        },
      }}
      telemetryHook={telemetryHook}
    />
  }

  const renderMain = (): JSX.Element => {
    if (!isAppReady)
      return renderInitialHeader();
    if (!isUserLoggedIn)
      return renderNotLoggedInHeader();

    return renderFullHeader();
  }

  return renderMain();

}

export const AppHeader = injectIntl(AppHeaderComponent);


import { AxiosError } from 'axios';
import { IHttpClient, IHttpClientRequest, IPortalConfig } from '@msx/platform-services';
import { IReportVisualProperties } from '../../../models/IReportVisualProperties';
import { 
    SAVE_DASHBOARD_VISUALS_CONFIG_BEGIN, 
    SAVE_DASHBOARD_VISUALS_CONFIG_SUCCESS, 
    SAVE_DASHBOARD_VISUALS_CONFIG_FAILURE,
} from '../..';
import { trackPromise } from 'react-promise-tracker';

export const saveDashboardVisualsConfigBegin = () => ({
type: SAVE_DASHBOARD_VISUALS_CONFIG_BEGIN
});

export const saveDashboardVisualsConfigSuccess = (payload: IReportVisualProperties) => ({
  type: SAVE_DASHBOARD_VISUALS_CONFIG_SUCCESS,
  payload: payload
});

export const saveDashboardVisualsConfigError = (error: AxiosError) => ({
  type: SAVE_DASHBOARD_VISUALS_CONFIG_FAILURE,
  payload: error
});

export const saveDashboardVisualsConfig = (httpClient: IHttpClient, visualsConfig: IReportVisualProperties[], appConfig:IPortalConfig) => {
    return async (dispatch: any) => {
        dispatch(saveDashboardVisualsConfigBegin());
        let saveVisualsObj : any = {
          reportVisuals : visualsConfig
        }
        try {
            const resource = appConfig.registrationConfig.resource;
            //const url = `${appConfig.registrationConfig.apiEndpoint}`;
            const url = `${appConfig.apiConfig.baseUrl}UserPreference`;
            const request: IHttpClientRequest = {
                resource: resource,
                data: saveVisualsObj
            };
            console.log(request);
            const response: any = await trackPromise(httpClient.post(url, request));
            dispatch(saveDashboardVisualsConfigSuccess(response.data ? response.data : null));
        } catch (err) {
            dispatch(saveDashboardVisualsConfigError(err));
        }
        
    }
}

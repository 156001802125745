import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { getConfig } from './AppLeftNav.config';
import { RouteComponentProps } from 'react-router';
import { CoherenceNav } from '@cseo/controls';
import { LeftNavProps } from './AppLeftNav.types';
import { IAppExtensionPageExtended } from './IAppExtensionPageExtended';
import { useSelector } from 'react-redux';
import { getExtensionsRegistration, getIsDevLogIn } from '../../../core/store';
import { ExtensionsRegistrationClient } from '@msx/platform-services';
import { IAppExtensionExtended } from './IAppExtensionExtended';

type Props = LeftNavProps & RouteComponentProps;

const AppLeftNavComponent: React.FC<Props> = (props) => {
  const { history } = props;

  const extensionsRegistration = useSelector(getExtensionsRegistration);
  const extensionsRegistrationClient = new ExtensionsRegistrationClient(extensionsRegistration);
  const isDevLogIn = useSelector(getIsDevLogIn);

  const telemetryHook = {
    trackMetric: function (metric, customProperties?: { [key: string]: unknown }): void {
      console.log('telemetryHook.trackMetric')
    },
    trackException: function (exception): void {
      console.log('trackException.trackException')
    },
    trackEvent: function (event, customProperties?: { [key: string]: unknown }): void {
      console.log('trackEvent.trackEvent')
    },
    trackTrace: function (trace, customProperties?: { [key: string]: unknown }): void {
      console.log('trackTrace.trackTrace')
    },
  };

  const renderLeftNav = () => {
    const { intl, isAppReady, extensionsPages, isUserLoggedIn } = props;
    var extensionRoutes = extensionsRegistrationClient.getRoutes();
    var extensions = extensionsRegistrationClient.getExtensions();
    let layoutConfig = getConfig(intl, isAppReady, history, extensionsPages as IAppExtensionPageExtended[], isUserLoggedIn, extensionRoutes, isDevLogIn, extensions as IAppExtensionExtended[]);
    return (
      <CoherenceNav
        appName={props.appName}
        groups={layoutConfig}
        onNavCollapsed={props.onNavCollapsed}
        telemetryHook={telemetryHook}
      />
    );
  }


  const renderMain = (): JSX.Element => {
    if (props.isNavCollapsed)
      return renderLeftNav();

    return renderLeftNav();
  }

  return renderMain();
}

export const AppLeftNav = withRouter(injectIntl(AppLeftNavComponent));

import React, { FC, useState, useEffect, useRef, useContext } from "react";
import { IAuditReportProps, IVisualProperties } from './reportvisualsconfig.types';
import { IReportVisualProperties } from '../../models/IReportVisualProperties'
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown, IDropdownOption, DropdownMenuItemType } from "office-ui-fabric-react/lib/Dropdown";
import { Separator } from '@fluentui/react/lib/Separator';
import axios from 'axios';
import { EditableGrid, EditControlType, IColumnConfig, EventEmitter, EventType, NumberAndDateOperators } from 'fluentui-editable-grid';
import { DetailsList, Selection, IColumn } from '@fluentui/react/lib/DetailsList';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { trackPromise } from 'react-promise-tracker';
import {
  IconButton,
  PrimaryButton,
  Stack,
  Link,
  Fabric,
  TextField,
  DirectionalHint,
  StackItem,
  Checkbox,
  CheckboxVisibility,
  DetailsListLayoutMode
} from "office-ui-fabric-react";
import {
  classNamesFunction,
  SelectionMode,
} from "office-ui-fabric-react/lib/Utilities";
import {
  getStyles,
  controlClass,
  stackTokens,
  dropdownStyles,
  stackTokens2,
} from "./reportvisualsconfig.styles";

import { IKeyValueItem } from "@msx/platform-services";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { IButtonProps } from '@fluentui/react/lib/Button';
import { TeachingBubble } from '@fluentui/react/lib/TeachingBubble';
import { useBoolean } from '@fluentui/react-hooks';
//import { ITeachingBubblePropsExtended, teachingBubbleConfig } from "../productList/TeachingBubbleConfig";
//import { httpGet, httpDelete, httpPost, httpPut } from '../../utils/apiCalls';
//import { getPBIAccessToken } from '../../../core/store';
import { PowerBIEmbed } from 'powerbi-client-react'
import { models, Report, Embed, Page, service, VisualDescriptor } from 'powerbi-client'
import { config } from "process";
import { access, truncateSync } from "fs";
import { ServiceContext, ApplicationContext } from '@msx/platform-services';
import { GetPBIToken } from '../../../core/utils/pbitokenhelper';
import { fetchDashboardVisualsConfig } from '../../store/actions/dashboardVisuals/dashboardvisualsConfig.action';
import { saveDashboardVisualsConfig } from '../../store/actions/dashboardVisuals/savedashboardVisualsConfig.action';
import { getdashboardVisualsConfig } from '../../store/selectors/dashboardVisuals/dashboardvisualsconfig.selector';
import { getdashboardVisualsConfigSaveLoadingStatus } from '../../store/selectors/dashboardVisuals/saveDashboardVisualsConfig.selector'
import { getExtensionMetadataList } from '../../store'
import { IExtensionMetadata } from "../../models/IExtensionMetadata";
import { appConfig } from '../../App.config';
import { fetchExtensionsRegistration, getExtensionsError, getIsDevLogIn } from '../../../core/store';
const getClassNames = classNamesFunction<any, any>();
let classes: any;

interface OwnProps extends InjectedIntlProps {
  //TODO: any 
}
type ReportConfigPageProps = OwnProps & InjectedIntlProps;

const ReportVisualsConfigComponent: FC<ReportConfigPageProps> = (props) => {
  const { intl } = props;
  //const theme = getCurrentTheme(parentContext);
  const appInsights = useAppInsightsContext();
  const reduxDispatch = useDispatch();
  const { authClient } = useContext(ServiceContext);
  const [teachingBubbleVisible, { toggle: toggleTeachingBubbleVisible }] = useBoolean(false);
  //const [teachingBubbleProps, setTeachingBubbleProps] = useState<ITeachingBubblePropsExtended>(teachingBubbleConfig[0])
  const [ReportsList, setReportsList] = useState<IDropdownOption[]>([]);
  const [WSsList, setWSsList] = useState<IDropdownOption[]>([]);
  const [AppsList, setAppsList] = useState<IDropdownOption[]>([]);
  const [WSnAppsList, setWSnAppsList] = useState<IDropdownOption[]>([]);
  const [SelectedWSId, setSelectedWSId] = useState<IDropdownOption>();
  const [SelectedReport, setSelectedReport] = useState<IDropdownOption>();
  //const pbiAccessToken = useSelector(getPBIAccessToken);
  const [accessToken, setAccessToken] = useState<string>('');
  const [accessToken1, setAccessToken1] = useState<string>('');
  const [reportId, setreportId] = useState<string>('');
  const [embedURL, setembedURL] = useState<string>('');
  const [loadReport, setloadReport] = useState<boolean>(false);
  const [reportvisualsList, setreportvisualsList] = useState<VisualDescriptor[]>([]);
  const [selectedvisualsList, setselectedvisualsList] = useState<IVisualProperties[]>([]);
  const savedVisualsList = useSelector(getdashboardVisualsConfig);
  //const [visualsList, setvisualsList] = useState<VisualDescriptor[]>([]);
  const saveDashboardVisualsConfigLoadingStatus = useSelector(getdashboardVisualsConfigSaveLoadingStatus);
  //const [selectedVisualsLst, setSelectedVisuals] = useState<IReportVisualProperties[]>([]); 
  //const selectedVisualsLst: IReportVisualProperties[] = useSelector(getdashboardVisualsConfig);
  //const extensionMetadataList: IExtensionMetadata[] = useSelector(getExtensionMetadataList);
  //const empl : IExtensionMetadata[] = useSelector(getdashboardVisuals); 
  //const [items, setItems] = useState(selectedVisualsLst);
  const [loadedReport, setLoadedReport] = useState<Report>();
  //const [tasks, setTasks] = useState(visualsList);
  const [gridItemSelection, setgridItemSelection] = useState<Selection>();
  const seperatorMsg = 'Selected Visuals';
  //classes = getClassNames(getStyles(theme));
  const { httpClient } = useContext(ServiceContext);
  const { appState } = useContext(ApplicationContext)
  const [selectionDetails, setSelectionDetails] = useState('');
  const [selectedItems, setSelectedItems] = useState<any[]>();
  const [selectedIndices, setSelectedIndices] = useState<number[]>([]);
  let _selection: Selection = new Selection({
    onSelectionChanged: () => setSelectionDetails(_getSelectionDetails()),
  });

  const [columns, setcolumns] = useState<IColumn[]>([
    {
      key: 'column1', name: 'Name', fieldName: 'title', minWidth: 250, maxWidth: 250, isResizable: true,
      isSorted: true,
      isSortedDescending: true,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A'
    },
  ]);

  const [gridColumns, setGridColumns] = useState<IColumnConfig[]>([
    {
      key: 'key',
      name: 'Visual Title',
      text: 'Visual Title',
      minWidth: 200,
      editable: false,
      includeColumnInExport: true,
      dataType: "string",
      applyColumnFilter: true,
    },
    {
      key: 'visualTitle',
      name: 'Visual Name',
      text: 'Visual Name',
      minWidth: 300,
      editable: true,
      includeColumnInExport: true,
      dataType: "string",
      applyColumnFilter: true,
    },
    {
      key: 'tags',
      name: 'Visual Tag',
      text: 'Visual Tag',
      minWidth: 200,
      editable: true,
      includeColumnInExport: true,
      dataType: "string",
      applyColumnFilter: true,
      inputType: EditControlType.Picker,
      pickerOptions: {
        pickerTags: ['Screening', 'Imports', 'Exports', 'LiveSite', 'home'],
        minCharLimitForSuggestions: 1,
        tagsLimit: 3
      }
    },
    {
      key: 'isActive',
      name: 'Active',
      text: 'Active',
      minWidth: 200,
      editable: true,
      includeColumnInExport: true,
      dataType: "string",
      applyColumnFilter: true,
      inputType: EditControlType.DropDown,
      dropdownValues: [
        { key: 'true', text: 'True' },
        { key: 'false', text: 'False' },
      ]
    }
  ]);

  const [reportConfig, setReportConfig] = useState<models.IReportEmbedConfiguration>({
    type: 'report',
    id: '0000',
    embedUrl: 'https://msit.powerbi.com/reportEmbed',
    tokenType: models.TokenType.Aad,
    accessToken: '',
    settings: {
      panes: {
        filters: {
          expanded: false,
          visible: false
        },
        bookmarks: {
          visible: false
        }
      },
      background: models.BackgroundType.Default,
    }
  },
  );

  useEffect(() => {
    fetchPBIAccessToken();
    fetchSavedVisuals();
  }, [authClient]);

  useEffect(() => {
    fetchWSforUser();
    fetchAppsforUser();
  }, [accessToken]);

  useEffect(() => {
    if (!saveDashboardVisualsConfigLoadingStatus) {
      reduxDispatch(fetchExtensionsRegistration(httpClient, [], appConfig, appInsights, true));
    }
  }, [saveDashboardVisualsConfigLoadingStatus]);

  useEffect(() => {
    if (SelectedWSId) {
      fetchReportsforWS();
    }
  }, [SelectedWSId]);

  useEffect(() => {
    if (WSsList || AppsList) {
      loadWSnApps();
    }
  }, [WSsList, AppsList]);

  useEffect(() => {
    if (SelectedReport) {
      setreportId(SelectedReport.key.toString());
      setembedURL(SelectedReport.title);
      setAccessToken1(accessToken);
      onloadReportClick();
    }
  },[SelectedReport]);

  // useEffect(() => {
  //   if (selectedVisualsLst) {
  //     loadSelectedVisualsinGrid();
  //   }
  // }, [selectedVisualsLst])

  useEffect(() => {
    if (savedVisualsList) {
      loadSavedVisual();
    }
    //console.log('selectedvisualsList');
  }, [savedVisualsList]);

  // Map of event handlers to be applied to the embedding report
  const eventHandlersMap = new Map([
    ['loaded', function () {
      console.log('Report has loaded');
    }],
    ['rendered', function () {
      console.log('Report has rendered');
      //loadVisuals();
      // Update display message
      //setMessage('The report is rendered')
    }],
    ['error', function (event?: service.ICustomEvent<any>) {
      if (event) {
        console.error(event.detail);
      }
    }]
  ]);

  function loadSelectedVisualsinGrid() {
    console.log('selectedVisualsLst');
  }

  function fetchSavedVisuals() {
    reduxDispatch(fetchDashboardVisualsConfig(httpClient, appState.appConfig));
  }

  const fetchReportsforWS = async () => {
    try {
      let apiEndPoint;
      if (SelectedWSId.title === 'ws')
        apiEndPoint = `https://api.powerbi.com/v1.0/myorg/groups/${SelectedWSId.key}/reports`;
      else if (SelectedWSId.title === 'app')
        apiEndPoint = `https://api.powerbi.com/v1.0/myorg/apps/${SelectedWSId.key}/reports`;

      let response = await fetch(apiEndPoint, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'X-Content-Type-Options': 'nosniff',
          'Authorization': "Bearer " + accessToken,
        },
      });
      var data = await response.json();
      fetchReportsforUserSuccess(data);
    }
    catch (error) {
      errorReportsCallBack(error);
    }
  }

  const fetchReportsforUserSuccess = (response: any) => {
    let ResponseList = response.value.map((name: any) => ({
      key: name.id,
      text: name.name,
      title: name.embedUrl
    }));
    ResponseList.sort((o1, o2) =>{ return o1.text.toUpperCase() > o2.text.toUpperCase() ? 1 : o1.text.toUpperCase() < o2.text.toUpperCase() ? -1 : 0; })
    setReportsList(ResponseList);
  }

  const errorReportsCallBack = (error: any) => {
    console.log("errorLoadReport" + error);
  }

  const fetchAppsforUser = async () => {
    try {
      const apiEndPoint = "https://api.powerbi.com/v1.0/myorg/apps";
      let response = await fetch(apiEndPoint, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'X-Content-Type-Options': 'nosniff',
          'Authorization': "Bearer " + accessToken,
        },
      });
      var data = await response.json();
      fetchAppsforUserSuccess(data);
    }
    catch (error) {
      errorAppsCallBack(error);
    }
  }

  const fetchAppsforUserSuccess = (response: any) => {
    let ResponseList = response.value.map((name: any) => ({
      key: name.id,
      text: name.name,
      title: 'app',
    }));
    ResponseList.sort((o1, o2) =>{ return o1.text.toUpperCase() > o2.text.toUpperCase() ? 1 : o1.text.toUpperCase() < o2.text.toUpperCase() ? -1 : 0; })
    ResponseList.unshift({ key: -2, text: "-", itemType: DropdownMenuItemType.Divider });
    ResponseList.unshift({ key: -1, text: "Apps", itemType: DropdownMenuItemType.Header });
    setAppsList(ResponseList);
  }

  const errorAppsCallBack = (error: any) => {
    console.log("AppsLoadError" + error);
  }

  async function fetchWSforUser() {
    try {
      //const apiEndPoint = "https://api.powerbi.com/v1.0/myorg/groups?$filter=contains(name,'Trade')";
      const apiEndPoint = "https://api.powerbi.com/v1.0/myorg/groups";
      let response = await fetch(apiEndPoint, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'X-Content-Type-Options': 'nosniff',
          'Authorization': "Bearer " + accessToken,
        },
      });
      var data = await response.json();
      fetchWSforUserSuccess(data);
    }
    catch (error) {
      console.log(error);
    }
  };

  const fetchWSforUserSuccess = (response: any) => {
    let ResponseList = response.value.map((name: any) => ({
      key: name.id,
      text: name.name,
      title: 'ws',
    }));
    ResponseList.sort((o1, o2) =>{ return o1.text.toUpperCase() > o2.text.toUpperCase() ? 1 : o1.text.toUpperCase() < o2.text.toUpperCase() ? -1 : 0; })
    ResponseList.unshift({ key: -4, text: "-", itemType: DropdownMenuItemType.Divider });
    ResponseList.unshift({ key: -3, text: "Work Spaces", itemType: DropdownMenuItemType.Header });
    setWSsList(ResponseList);
  }

  const errorWSCallBack = (error: any) => {
    console.log("WSLoadError" + error);
  }

  const loadWSnApps = () => {
    let ResponseList = WSsList.map((name: IDropdownOption) => ({
      key: name.key,
      text: name.text,
      itemType: name.itemType,
      title: name.title,
    }));
    AppsList.forEach((item) => {
      let item1: any = {
        key: item.key,
        text: item.text,
        itemType: item.itemType,
        title: item.title,
      }
      ResponseList.push(item1);
    })
    setWSnAppsList(ResponseList);
  }

  const fetchPBIAccessToken = async () => {
    const resource = process.env.REACT_APP_PBI_RESOURCE_ID;
    await authClient.acquireToken(resource)
      .then(token => {
        setAccessToken(token);
      })
  };

  function onWSorAppSelectionChange(
    event: React.FormEvent<HTMLDivElement>,
    option: IDropdownOption | undefined
  ) {
    if (option === undefined) {
      console.log("no WS/App selected")
      return;
    }
    else {
      let selectedItem: any = option;
      setSelectedWSId(selectedItem);
    }
  }

  function onReportSelectionChange(event: React.FormEvent<HTMLDivElement>, option: IDropdownOption | undefined) {
    if (option === undefined) {
      console.log("no Report selected")
      return;
    }
    else {
      let selectedItem: any = option;
      setSelectedReport(selectedItem);
      //setloadReport(false);
    }
  }

  function onloadReportClick() {
    setReportConfig({
      ...reportConfig,
      id: SelectedReport.key.toString(),
      embedUrl: SelectedReport.title,
      accessToken: accessToken
    });
  }

  const loadVisuals = async () => {
    if (!loadedReport) {
      console.log('Report not available');
    }
    else {
      setreportvisualsList([]);
      const pages = await loadedReport.getPages();
      let page = pages.filter(function (page) {
        return page.isActive
      })[0];
      const visuals = await page.getVisuals();
      let vList = visuals.map((visual: VisualDescriptor) => ({
        name: visual.name + '|' + visual.title + '|' + visual.type,
        pageName: visual.page.name,
      }));
      let sortedVisuals = visuals.sort(compare);
      setreportvisualsList(sortedVisuals);
    }
  }

  function compare(a, b) {
    return b.title - a.title;
  }

  function _getSelectionDetails(): string {
    const count = _selection.getSelectedCount();
    setSelectedItems(_selection.getSelection())
    setSelectedIndices(_selection.getSelectedIndices());

    switch (count) {
      case 0:
        return 'No items selected';
      case 1:
        return '1 item selected: ';
      default:
        return `${count} items selected`;
    }
  }

  function addVisuals() {
    let newselectedVisualsList: IVisualProperties[] = [...selectedvisualsList];
    selectedItems.forEach(visual => {
      let item = newselectedVisualsList.filter(x => x.key == visual.name);
      if (newselectedVisualsList.indexOf(item[0]) < 0)
        newselectedVisualsList.push(getVisualObject(visual));
    });
    setselectedvisualsList(newselectedVisualsList);
  }

  function saveSubmitVisuals(data: any[]) {

    if (data && data.length > 0) {
      let newselectedVisualsLstSave: IReportVisualProperties[] = [];
      data.forEach(visual => {
        let op = visual['_grid_row_operation_'];
        if (op != 4) {
          newselectedVisualsLstSave.push(getReportVisualObject(visual));
        }
      });
      reduxDispatch(saveDashboardVisualsConfig(httpClient, newselectedVisualsLstSave, appState.appConfig));
    }
  }

  function loadSavedVisual() {
    let savedVisuals: IVisualProperties[] = [];
    if (savedVisualsList) {
      savedVisualsList.forEach(v => {
        if (v.attributes) {
          let visual: IVisualProperties = {
            key: v.key,
            type: v.attributes.type,
            reportId: v.attributes.reportId,
            embedURL: v.attributes.embedURL,
            accessToken: v.attributes.accessToken,
            visualName: v.attributes.visualName,
            pageName: v.attributes.pageName,
            visualTitle: v.attributes.visualTitle,
            tags: v.attributes.tags,
            isActive: v.active
          }
          savedVisuals.push(visual);
        }
      })
    }
    setselectedvisualsList(savedVisuals);
  }

  const getVisualObject = (visual: VisualDescriptor): IVisualProperties => {
    return {
      key: visual.name,
      type: 'visual',
      reportId: visual.page.report.config.id,
      embedURL: visual.page.report.config.embedUrl,
      accessToken: '',
      visualName: visual.name,
      pageName: visual.page.name,
      visualTitle: visual.title,
      tags: [],
      isActive: true
    }
  };

  // function getVisualTags(v) {
  //   let tags: string[] = [];
  //   if (selectedvisualsList) {
  //     let item = selectedvisualsList.filter(x => x.key == v.name);
  //     tags = item[0].tags ? item[0].tags : [];
  //   }
  //   return tags;
  // }

  const getReportVisualObject = (visual: IVisualProperties): IReportVisualProperties => {
    return {
      key: visual.key,
      name: "ReportTile",
      description: "Report Tile",
      component: "rpt-tile",
      active: visual.isActive,
      attributes: {
        type: 'visual',
        reportId: visual.reportId,
        embedURL: visual.embedURL,
        accessToken: '',
        visualName: visual.visualName,
        pageName: visual.pageName,
        visualTitle: visual.visualTitle,
        tags: getTags(visual.tags)
      }
    }
  };

  function getTags(t) {
    let tags: string[] = [];
    tags = [...t];
    return tags;
  }

  const renderMain = (): JSX.Element => {
    return (
      <Fabric>
        <Stack horizontalAlign="start" horizontal tokens={stackTokens2}>
          <Stack.Item grow={1}>
            <Dropdown
              placeholder="Select a work space/App"
              label="Work Space/Apps:"
              multiSelect={false}
              options={WSnAppsList}
              ariaLabel="Select a Work Space or App"
              styles={dropdownStyles}
              onChange={onWSorAppSelectionChange}
            />
          </Stack.Item>
          <Stack.Item grow={1}>
            <Dropdown
              placeholder="Select a report"
              label="Reports:"
              multiSelect={false}
              options={ReportsList}
              ariaLabel="Select a Report"
              styles={dropdownStyles}
              onChange={onReportSelectionChange}
            />
          </Stack.Item>

          {/* <Stack.Item grow={1}>
            <PrimaryButton
              text="Load"
              id="loadReport"
              className={controlClass.submitStyles}
              ariaLabel="Load Report"
              onClick={onloadReportClick}
            />
          </Stack.Item> */}
          <Stack.Item grow={1}>
            <PrimaryButton
              text="Get Visuals"
              id="getVisuals"
              className={controlClass.submitStyles}
              ariaLabel="Get Visuals"
              onClick={loadVisuals}
            />
          </Stack.Item>
          <Stack.Item grow={1}>
            <PrimaryButton
              text="Add Visuals"
              id="AddVisuals"
              className={controlClass.submitStyles}
              ariaLabel="Save Visuals"
              onClick={addVisuals}
            />
          </Stack.Item>
        </Stack>
        <>

          <Stack verticalAlign="start" verticalFill tokens={stackTokens}>
            <Stack.Item grow={2}>
              <Stack horizontalAlign="start" horizontal tokens={stackTokens}>
                <Stack.Item>
                  <div id="pbiEmbed">
                    <PowerBIEmbed
                      embedConfig={reportConfig}
                      eventHandlers={eventHandlersMap}
                      cssClassName={controlClass.Embedcontainer}
                      getEmbeddedComponent={(embeddedReport: Embed) => {
                        //console.log(`Embedded object of type "${embeddedReport.config.embedUrl}" received`);
                        setLoadedReport(embeddedReport as Report);
                      }}
                    />
                  </div>
                </Stack.Item>
                <Stack.Item>
                  <DetailsList
                    items={reportvisualsList}
                    columns={columns}
                    setKey="set"
                    selection={_selection}
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    selectionPreservedOnEmptyClick={true}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="select row"
                    className={controlClass.gridStyle}
                    checkboxVisibility={CheckboxVisibility.always}
                    selectionMode={SelectionMode.multiple}
                  />
                </Stack.Item>
              </Stack>

            </Stack.Item>
            <Stack.Item>
              <Separator>{seperatorMsg}</Separator>
            </Stack.Item>
            <Stack.Item grow={1}>
              {
                <EditableGrid
                  id={1}
                  enableColumnEdit={true}
                  enableSave={true}
                  columns={gridColumns}
                  checkboxVisibility={CheckboxVisibility.always}
                  layoutMode={DetailsListLayoutMode.justified}
                  selectionMode={SelectionMode.multiple}
                  enableRowEdit={true}
                  enableRowEditCancel={true}
                  enableBulkEdit={false}
                  items={selectedvisualsList}
                  enableCellEdit={false}
                  enableExport={false}
                  enableTextFieldEditMode={true}
                  enableTextFieldEditModeCancel={true}
                  enableGridRowsDelete={true}
                  height={"70vh"}
                  width={"75vw"}
                  position={"relative"}
                  enableUnsavedEditIndicator={false}
                  onGridSave={saveSubmitVisuals}
                  enableGridReset={false}
                  enableColumnFilters={true}
                  onGridSelectionChange={(s) => {
                    //console.log(s);
                  }}
                />
              }
            </Stack.Item>

          </Stack>
        </>
      </Fabric >
    );
  };

  return renderMain();
};

export const ReportVisaulsConfig = injectIntl(ReportVisualsConfigComponent);

import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from "react-router";
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { MsxTileList, ApplicationContext } from '@msx/platform-services'
import { Extension } from '..';
import { Stack } from '@fluentui/react';
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
import { getStyles } from './TileList.styles';
import { IAppExtensionExtended } from '../../../app/components/leftNav/IAppExtensionExtended';


const getClassNames = classNamesFunction<any, any>();
let classes: any;

interface OwnProps extends InjectedIntlProps {
  isEdit?: boolean;
  sortableContainer?: any;
  sortableItem?: any;
}
type TileListProps = OwnProps & RouteComponentProps;


export const TileListComponent: (React.FC<TileListProps>) = props => {
  const { appState, extensionsRegistrationClient } = useContext(ApplicationContext);
  const tileExtensions = extensionsRegistrationClient.getExtensionsTiles();
  classes = getClassNames(getStyles, appState.theme);

  const getTiles = () => {
    var extensions: IAppExtensionExtended[] = extensionsRegistrationClient.getExtensions() as IAppExtensionExtended[];

    const tiles = [];
    tileExtensions.forEach(tile => {
      if (tile.active) {
        var tileAttributes = tile['attributes'];
        if (tileAttributes) {
          var tags = tileAttributes['tags'] as string[];
          if (tags && tags.length > 0) {
            let i = tags.findIndex(t => t === 'home')
            if (i >= 0)
              tiles.push(
                <Extension componentKey={tile.key} key={tile.key} isDashboard={true} />
              );
          }
        }
        else {
          var ext = extensions.filter(x => x.tiles.filter(y => y.key == tile.key).length > 0);
          if (ext.length > 0 && ext[0].authorized) {
            tiles.push(
              <Extension componentKey={tile.key} key={tile.key} isDashboard={true} />
            );
          }
        }
      }
    });
    return tiles;
  }


  const renderMain = (): JSX.Element => {
    const tiles = getTiles();
    return (
      <Stack className={classes.root}>
        <MsxTileList tiles={tiles} />
      </Stack>
    );
  }
  return renderMain();

}
export const TileList = withRouter(injectIntl(TileListComponent));

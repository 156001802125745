import React, { FC, useRef, useContext, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, BrowserRouter, Route, Switch } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Customizer } from 'office-ui-fabric-react';
import { useBoolean } from '@uifabric/react-hooks';
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
import { CoherenceCustomizations } from '@cseo/styles';
import { DarkCustomizations } from '@msx/react-ui-component';
import {
  IApplicationContext, ServiceContext, ExtensionsRegistrationClient,
  ApplicationContext, useUser,
} from '@msx/platform-services';
import {
  fetchUserProfile, fetchUserProfileBegin, fetchUserProfileSuccess, getUserProfileLoadingStatus,
  getExtensionsLoadingStatus, fetchExtensionsRegistration, getExtensionsRegistration,
  getCurrentLocale, setCurrentLocale,
  getCurrentTheme, setCurrentTheme,
  getUserDashboardTiles, getCurrentAppState, setDevLogIn
} from '../../store';
import { PageError } from '../'
import { AppHeader, AppLeftNav } from '../../../app/components'
import { appConfig } from '../../../app/App.config';
import { getStyles } from './Shell.styles';
import { IUserProfile } from '../../models';
import { BusyIndicator } from '../';
import { messages } from './Shell.messages';
import { getThemeByName } from '../../utils';
import { Signin, } from '../../../app/pages';
import { ShellProps } from './Shell.types';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { Client } from "@microsoft/microsoft-graph-client";
import { GraphConfig, Scopes } from './Graph.Config';
import { LoadingIndicator } from '../shared/loadingindicator';

const getClassNames = classNamesFunction<any, any>();
let classes: any;

const ShellComponent: FC<ShellProps> = (props) => {
  const { intl } = props;
  const reduxDispatch = useDispatch();
  const currentAppState = useSelector(getCurrentAppState);
  const user = useUser();
  const currentTheme = useSelector(getCurrentTheme);
  const userDashboardTiles = useSelector(getUserDashboardTiles);
  const userProfileLoadingStatus = useSelector(getUserProfileLoadingStatus);
  const extensionsLoadingStatus = useSelector(getExtensionsLoadingStatus);
  const [showFabricComponent, setShowFabricComponent] = useState(false);
  const extensionsRegistration = useSelector(getExtensionsRegistration);
  const extensionsRegistrationClient = new ExtensionsRegistrationClient(extensionsRegistration);
  const [isStartupError, { setTrue: activateStartupError, setFalse: dismissStartupError }] = useBoolean(false);
  const [startupError, setStartupError] = useState('');
  const currentLocale = useSelector(getCurrentLocale);
  const currentLocaleRef = useRef(currentLocale);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const { httpClient, authClient } = useContext(ServiceContext);
  const [isNavCollapsed, setIsNavCollapsed] = useState(false);

  classes = getClassNames(getStyles, currentTheme);
  const appInsights = useAppInsightsContext();

  useEffect(() => {
    //MOUNT
    loadStartupData();
    //loadStartupDataAfterAuthorization();
    window.removeEventListener('resize', handleResize);
    window.addEventListener('resize', handleResize);
    //UNMOUNT
    return () => {
      window.removeEventListener('resize', handleResize);
    }
    // eslint-disable-next-line
  }, [])

  const handleResize = (): void => {
    //setIsSmallScreen(window.innerWidth <= ScreenWidthMaxSmall);
  }

  const loadStartupData = async () => {
    var isAuthorized = false;
    var isLoggedIn = await authClient.isLoggedIn();
    setIsUserLoggedIn(isLoggedIn);
    if (!isLoggedIn) {
      return;
    }

    await authClient.acquireToken(Scopes)
      .then(async token => {
        var gClient = Client.init({
          authProvider: (done) => { done(null, token); }
        });

        await gClient.api(GraphConfig.graphurl)
          .query(GraphConfig.graphQuery).get()
          .then((groups: any) => {
            if (groups.value != null) {
              let selectedG = groups.value.find((g) => g.id == GraphConfig.authGrpId);
              if (selectedG != null)
                reduxDispatch(setDevLogIn(true));
              else
                reduxDispatch(setDevLogIn(false));

              loadStartupDataAfterAuthorization();
            }
          })
      });
  };



  const loadStartupDataAfterAuthorization = async () => {
    var isLoggedIn = await authClient.isLoggedIn();
    setIsUserLoggedIn(isLoggedIn);
    if (!isLoggedIn) {
      return;
    }

    const Window = window as any;
    if (Window.hasStartupDataLoaded === true) return;
    console.log('Loading startup data...')
    try {
      dismissStartupError();
      // load user profile
      if (appConfig.enableUserSettingsApi) {
        reduxDispatch(fetchUserProfileBegin());
        const userProfile = await fetchUserProfile(httpClient, appConfig, appInsights)
        reduxDispatch(fetchUserProfileSuccess(userProfile as IUserProfile));
        const locale = (userProfile as IUserProfile).userPreference.locale;
        if (locale !== currentLocaleRef.current) {
          reduxDispatch(setCurrentLocale(locale));
        }
        const themeName = (userProfile as IUserProfile).userPreference.theme;
        if (currentTheme.name !== themeName) {
          const newTheme = getThemeByName(themeName);
          reduxDispatch(setCurrentTheme(newTheme));
        }
      }
      // load extension registraton      
      if (appConfig.registrationConfig.active) {
        reduxDispatch(fetchExtensionsRegistration(httpClient, [], appConfig, appInsights, false));
      }
      Window.hasStartupDataLoaded = true;
    } catch (error) {
      setStartupError(JSON.stringify(error));
      activateStartupError();
      appInsights.trackException({ exception: new Error(error) });
    }
  };

  const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const handleHeaderButtonAccess = async () => {
    await sleep(500);

    // show/hide feedback button
    let showFeedback = currentAppState.isReady && isUserLoggedIn;
    if (!appConfig.ocvFeedbackConfig.active) {
      showFeedback = false;
    }
    const el: any = document.querySelector('#coher-header-ocv-button-id');
    if (el) el.style.display = showFeedback ? '' : 'none';
  }

  const handleFabricVisibilityChange = (isVisible: boolean) => {
    setShowFabricComponent(isVisible);
  }

  const handleLocaleChange = (locale) => {
    reduxDispatch(setCurrentLocale(locale));
  }

  const handleNavCollapsed = (isCollapsed) => {
    setIsNavCollapsed(isCollapsed);
  }

  const renderAppHeader = () => {
    return (
      <AppHeader
        showFabricComponent={showFabricComponent}
        onFabricVisibilityChanged={handleFabricVisibilityChange}
        locales={getDisplayLocales()}
        dispatchUpdateLocaleAction={handleLocaleChange}
        isAppReady={currentAppState.isReady}
        theme={currentTheme}
        appName={appConfig.appName}
        isUserLoggedIn={isUserLoggedIn}
      />
    );
  }
  const renderLeftNav = (): JSX.Element => {
    if (!isUserLoggedIn) {
      return;
    }
    return (
      <AppLeftNav
        appName={appConfig.appName}
        isAppReady={currentAppState.isReady}
        extensionsPages={extensionsRegistrationClient.getExtensionsPages()}
        isUserLoggedIn={isUserLoggedIn}
        theme={currentTheme}
        onNavCollapsed={handleNavCollapsed}
        isNavCollapsed={isNavCollapsed}

      />
    );
  };

  // const renderAppFooter = () => {
  //   return <AppFooter />
  // }

  const renderRoutes = () => {
    if (!isUserLoggedIn) {
      return (
        <Switch>
          <Route exact path="/" component={Signin} />
          <Route path="/null" component={Signin} />
          <Route component={Signin} />
        </Switch>
      );
    }
    if (isStartupError) {
      return <PageError error={startupError} />
    }
    if (appConfig.enableUserSettingsApi && userProfileLoadingStatus) {
      return <BusyIndicator message={intl.formatMessage(messages.loadingUserProfile)} />
    }
    if (appConfig.registrationConfig.active) {
      if (extensionsLoadingStatus) {
        return <BusyIndicator message={intl.formatMessage(messages.loadingExtensionRegistraton)} />
      }
    }
    return props.onRenderRoutes(extensionsRegistrationClient.getExtensionsPages());
  }

  const getDisplayLocales = () => {
    let result = [];
    if (!appConfig.languageConfig.active) {
      return result;
    }
    const locales = appConfig.languageConfig.locales;
    Object.keys(locales).forEach(function (key, idx) {
      result.push(
        {
          key: key,
          text: locales[key]
        });
    });
    return result;
  }

  const renderMain = (): JSX.Element => {
    currentLocaleRef.current = currentLocale;
    const themeCustomizations = getThemeCustomizations();
    const newAppState = {
      ...currentAppState,
      user,
      appConfig,
      locale: currentLocale,
      theme: currentTheme,
      isUserLoggedIn: isUserLoggedIn,
      isNavCollapsed: isNavCollapsed,
      themeCustomizations: themeCustomizations,
    };
    const contextValue: IApplicationContext = {
      extensionsRegistrationClient: extensionsRegistrationClient,
      appState: newAppState,
      userDashboardTiles: userDashboardTiles,
    }
    return (
      <ApplicationContext.Provider value={contextValue}>
        {renderApp()}
      </ApplicationContext.Provider>
    )
  }

  const getThemeCustomizations = () => {
    const themeCustomizations = currentTheme.name === 'dark' ? DarkCustomizations : CoherenceCustomizations;
    return themeCustomizations;
  }

  const renderApp = (): JSX.Element => {
    handleHeaderButtonAccess();
    const themeCustomizations = getThemeCustomizations();
    return (
      <Customizer {...themeCustomizations}>
        {renderAppHeader()}
        <BrowserRouter>
          <>
            <div>
              <LoadingIndicator />
            </div>
            {renderLeftNav()}
            <main id='main' className={classes.main} tabIndex={-1}>
              {renderRoutes()}
            </main>
          </>
        </BrowserRouter>
        <div role="alert" id="announce" aria-live="polite" aria-relevant="additions text"></div>
      </Customizer>
    )
  }

  // const renderMobileLayout = () => {
  //   const bodyStackStyles: IStackStyles = { root: { minHeight: 'calc(100vh - 48px)', }, };
  //   const bodyStackTokens: IStackTokens = { childrenGap: 0, padding: 0, };
  //   const stackmainContainerStyles: IStackItemStyles = { root: { padding: 0, width: '100%' }, };
  //   return (
  //     <Stack tokens={bodyStackTokens}>
  //       {renderLeftNav()}
  //       <Stack.Item align="stretch" styles={stackmainContainerStyles} >
  //         <Stack>
  //           <main id='main' className={classes.main} tabIndex={-1}>
  //             {renderRoutes()}
  //           </main>
  //         </Stack>
  //       </Stack.Item>
  //     </Stack>
  //   );

  // }

  //   const renderGeneralLayout = () => {
  //     const bodyStackTokens: IStackTokens = { childrenGap: 0, padding: 0, };
  //     const stackmainContainerStyles: IStackItemStyles = { root: { padding: 5, width: '100%' }, };
  //     return (
  //       <Stack horizontal tokens={bodyStackTokens}>
  //         {renderLeftNav()}
  //         <Stack.Item align="stretch" styles={stackmainContainerStyles} >
  //           <Stack>
  //             <main id='main' className={classes.main} tabIndex={-1}>
  //               {renderRoutes()}
  //             </main>
  //           </Stack>
  //         </Stack.Item>
  //       </Stack>
  //     );

  //   }

  return renderMain();
};

export const Shell = withRouter(injectIntl(ShellComponent));

import { AxiosError, AxiosResponse } from 'axios';
import {
  FETCH_EXTENSIONS_REGISTRATION_BEGIN,
  FETCH_EXTENSIONS_REGISTRATION_SUCCESS,
  FETCH_EXTENSIONS_REGISTRATION_FAILURE,
} from '..';
import {
  IHttpClient,
  IHttpClientRequest,
  IExtensionsRegistration,
  IAppExtension,
  IKeyValueItem,
  IPortalConfig
} from '@msx/platform-services';
import { trackPromise } from 'react-promise-tracker';

import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const emptyData = {
  routes: [],
  extensions: [],
  catalogue: [],
};

export const fetchExtensionsRegistrationBegin = () => ({
  type: FETCH_EXTENSIONS_REGISTRATION_BEGIN,
});

export const fetchExtensionsRegistrationSuccess = (payload: IExtensionsRegistration) => ({
  type: FETCH_EXTENSIONS_REGISTRATION_SUCCESS,
  payload: payload,
});

export const fetchExtensionsRegistrationError = (error: AxiosError) => ({
  type: FETCH_EXTENSIONS_REGISTRATION_FAILURE,
  payload: error,
});


// export const fetchExtensionsRegistration = (httpClient: IHttpClient, testExtensions: IKeyValueItem[], appConfig: IPortalConfig) => {
//   return async (dispatch: any, getState: any) => {
//     debugger;
//     if (
//       getState().extensionsRegistration &&
//       getState().extensionsRegistration.extensionsRegistration.extensions.length > 0
//     ) {
//       return getState().extensionsRegistration.extensionsRegistration;
//     }

//     try {
//      // const resource = appConfig.registrationConfig.resource;
//       const url = `${appConfig.registrationConfig.apiEndpoint}`;
//       // const request: IHttpClientRequest = {
//       //   resource: resource,
//       // };
//       //const response: any = await httpClient.get(url, request);
//       //const data = processExtensionsRegistration(response, testExtensions);
//       axios.get(url)
//       .then((res)=>{
//         console.log('res');
//         console.log(res);
//         dispatch(fetchExtensionsRegistrationSuccess(res.data));
//       }).catch((err)=>{
//         dispatch(fetchExtensionsRegistrationError(err));
//       })
//       //dispatch(fetchExtensionsRegistrationSuccess(data));
//     } catch (err) {
//       // dispatch(fetchExtensionsRegistrationError(err));
//     }
//   };
// };

export const fetchExtensionsRegistration = (httpClient: IHttpClient, testExtensions: IKeyValueItem[], appConfig: IPortalConfig, appInsights: ReactPlugin, forceLoad: boolean) => {
  return async (dispatch: any, getState: any) => {
    if (
      !forceLoad &&
      getState().extensionsRegistration &&
      getState().extensionsRegistration.extensionsRegistration.extensions.length > 0
    ) {
      return getState().extensionsRegistration.extensionsRegistration;
    }

    try {
      const resource = appConfig.registrationConfig.resource;
      const url = `${appConfig.registrationConfig.apiEndpoint}`;
      const request: IHttpClientRequest = {
        resource: resource,
      };
      const response: any = await trackPromise(httpClient.get(url, request));
      const data = processExtensionsRegistration(response, testExtensions);
      dispatch(fetchExtensionsRegistrationSuccess(data));
    } catch (err) {
      dispatch(fetchExtensionsRegistrationError(err));
      appInsights.trackException({exception: new Error(err)});
    }
  };
};

const processExtensionsRegistration = (res: AxiosResponse, testExtensions: IKeyValueItem[]) => {
  if (!res.data) return emptyData;
  const result = res.data as IExtensionsRegistration;

  // enable/disable extension based on catalogue settings
  const catalogue = result.catalogue;

  if (catalogue.length > 0) {
    catalogue.forEach((group, i) => {
      group.items.forEach((item, j) => {
        const extension = result.extensions.find((ext) => ext.key === item.key);
        if (extension) {
          const active = item.active;
          extension.active = active;
          extension.pages.forEach((page) => {
            page.active = active;
          });
          extension.tiles.forEach((tile) => {
            tile.active = active;
          });
        }
      });
    });
  }

  // enable side loading support
  testExtensions.forEach((testExtension) => {
    const extension = result.extensions.find((item) => item.key === testExtension.key);
    if (extension) {
      enableSideLoading(extension, testExtension.value);
    }
  });
  return result;
};

const enableSideLoading = (extension: IAppExtension, url: string) => {
  extension.active = true;
  extension.files.forEach((item) => {
    item.url = url;
    if (!item.log) {
      item.active = false;
    }
  });
  extension.pages.forEach((item) => {
    item.active = true;
  });
  extension.tiles.forEach((item) => {
    item.active = true;
  });
};

import React from 'react';
import {
  IStyle, mergeStyleSets, ActionButton, IIconProps,
  mergeStyles
} from 'office-ui-fabric-react';
import { ExtendedTypography, HyperlinkColors } from '@cseo/styles';


const SettingsStyles = mergeStyleSets({
  root: {
    marginLeft: '-10px',
    marginRight: '-10px'
  } as IStyle,
  toggle: {
    marginTop: '3px',
    marginLeft: '10px'
  } as IStyle
});

export const ProfilePanelSampleStyles = {
  root: [
    mergeStyles(ExtendedTypography.size14Semibold),
    {
      color: HyperlinkColors.hyperlink
    }
  ],
  rootHovered: [
    mergeStyles(ExtendedTypography.size14Semibold),
    {
      color: HyperlinkColors.hyperlinkHoverPressed
    }
  ],
  rootPressed: [
    mergeStyles(ExtendedTypography.size14Semibold),
    {
      color: HyperlinkColors.hyperlinkHoverPressed
    }
  ],
  icon: [
    mergeStyles(ExtendedTypography.size14Semibold),
    {
      color: HyperlinkColors.hyperlink
    }
  ],
  iconHovered: [
    mergeStyles(ExtendedTypography.size14Semibold),
    {
      color: HyperlinkColors.hyperlinkHoverPressed
    }
  ],
  iconPressed: [
    mergeStyles(ExtendedTypography.size14Semibold),
    {
      color: HyperlinkColors.hyperlinkHoverPressed
    }
  ]
};

const settingsIcon: IIconProps = { iconName: 'Settings' };
const TouchIcon: IIconProps = { iconName: 'Touch' };

export const ProfilePanelExample: JSX.Element = (
  <div className={SettingsStyles.root}>
    <ActionButton iconProps={settingsIcon} styles={ProfilePanelSampleStyles}>
      General
          </ActionButton>
    <br />
    <ActionButton iconProps={TouchIcon} styles={ProfilePanelSampleStyles}>
      Accessibility
          </ActionButton>
  </div>
);
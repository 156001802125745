import { createSelector } from 'reselect';
import { ReduxRootState, ReduxExtensionMetadataCrudState, ReduxExtensionMetadataJsonUploadState, } from '../..';
import { AxiosError } from 'axios';

const extensionMetadataCrudState = (state: ReduxRootState): ReduxExtensionMetadataCrudState => state.extensionMetadataCrud;

export const getExtensionMetadataCrudLoadingStatus = createSelector(
    extensionMetadataCrudState,
  (extensionMetadataCrudState: ReduxExtensionMetadataCrudState): boolean => extensionMetadataCrudState.isLoading
);

export const getExtensionMetadataCrudError = createSelector(
    extensionMetadataCrudState,
  (extensionMetadataCrudState: ReduxExtensionMetadataCrudState): AxiosError => extensionMetadataCrudState.error
);



const extensionMetadataJsonUploadState = (state: ReduxRootState): ReduxExtensionMetadataJsonUploadState => state.extensionMetadataJsonUpload;

export const getExtensionMetadataJsonUploadLoadingStatus = createSelector(
  extensionMetadataJsonUploadState,
  (extensionMetadataJsonUploadState: ReduxExtensionMetadataJsonUploadState): boolean => extensionMetadataJsonUploadState.isLoading
);

export const getExtensionMetadataJsonUploadError = createSelector(
  extensionMetadataJsonUploadState,
  (extensionMetadataJsonUploadState: ReduxExtensionMetadataJsonUploadState): AxiosError => extensionMetadataJsonUploadState.error
);


import { AnyAction } from 'redux';
import {
  CRUD_TODO_LIST_BEGIN,
  CRUD_TODO_LIST_SUCCESS,
  CRUD_TODO_LIST_FAILURE,
  ReduxToDoCrudState
} from '..';


const todoCrudInitialState: ReduxToDoCrudState = {
  isLoading: true,
  error: null,
};

export default function todoCrudReducer(
  state: ReduxToDoCrudState = todoCrudInitialState,
  action: AnyAction
) {
  switch (action.type) {
    case CRUD_TODO_LIST_BEGIN:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case CRUD_TODO_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case CRUD_TODO_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
}

import React, { useContext, useState, useEffect } from 'react';
import { ServiceContext } from '@msx/platform-services';
import { useSelector, useDispatch } from 'react-redux';
import { setPBIAccessToken, getPBIAccessToken, ReduxPBITokenState } from '../store';

let pbitoken: string;

export function GetPBIToken() {
    const { authClient } = useContext(ServiceContext);
    const reduxDispatch = useDispatch();
    try {
        const pbiAccessToken = useSelector(getPBIAccessToken);
        if (pbiAccessToken) {
            const tokenRefreshTime = new Date(Date.now() + 10 * 60000);
            if (pbiAccessToken.expiresBy <= tokenRefreshTime) {
                generatePBIAccessToken();
                return pbitoken;
            }
            else {
                console.log('use token from store');
                pbitoken = pbiAccessToken.token;
                return pbitoken;
            }
        }
    }
    catch (ex) {

    }

    async function generatePBIAccessToken() {
        console.log('generate new token');
        await authClient.acquireToken(process.env.REACT_APP_PBI_RESOURCE_ID)
            .then(async token => {
                pbitoken = token;
                let newtoken: ReduxPBITokenState = {
                    token: token,
                    expiresBy: new Date(Date.now() + 60 * 60000)
                };
                reduxDispatch(setPBIAccessToken(newtoken))
                return pbitoken;
            })

    }

}

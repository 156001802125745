import { InjectedIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getIsDevLogIn } from '../core/store';
import { messages } from './App.navigation.messages';
import { AppRoutePath } from './App.types';

export const getLeftNavMenuItems = (intl: InjectedIntl, history: any, isDevLogIn: boolean) => {
  const items = [
  ];

  if(isDevLogIn){
    items.push({
      name: intl.formatMessage(messages.adminPage),
      key: 'left_nav_menu_adminPage',
      ariaLabel: intl.formatMessage(messages.adminPage),
      icon: 'Admin',
      onClick: () => {
        history.push(AppRoutePath.AdminPage);
      },
      isSelected:
        window.location.href === window.location.protocol + '//' + window.location.host + AppRoutePath.AdminPage ? true : false,
    });
  }
  
  return items;

}

export const getExtraLeftNavMenuItems = (intl: InjectedIntl, history: any) => {

  const items =
  {
    key: "group2",
    name: "External apps links",
    groupTitleAttributes: {
      id: "nav_group_2"
    },
    links: [
      {
        name: "Screening",
        key: "leftNavScreening",
        ariaLabel: "Screening",
        icon: "WaffleOffice365",
        url: null,
        links: [
          {
            name: "Bridger",
            key: "leftNavBridger",
            ariaLabel: "Bridger",
            // onClick: () => {
            //   window.open("https://chessuiprod.trafficmanager.net/XgAuth/", '_blank')
            // }
            onClick: () => {
              history.push(AppRoutePath.Dashboard);
            },
            isSelected:
              window.location.href === window.location.protocol + '//' + window.location.host + AppRoutePath.Dashboard ? true : false,
          },
          {
            name: "BIS",
            key: "leftNavBIS",
            ariaLabel: "BIS",
            onClick: () => {
              window.open("https://sapprd15ms.redmond.corp.microsoft.com/sap/bc/ui2/flp?sap-client=300&sap-language=EN", '_blank')
            }
          },
        ]
      },
      {
        name: "Import Export",
        key: "leftNavImportExport",
        ariaLabel: "Import Export",
        icon: "WaffleOffice365",
        url: null,
        links: [
          {
            name: "India Imports",
            key: "leftNavIndiaImports",
            ariaLabel: "India Imports",
            onClick: () => {
              window.open("https://tradeexperience.trafficmanager.net/", '_blank')
            }
          },
          {
            name: "Trade BI Reports",
            key: "leftNavTradeBIReports",
            ariaLabel: "Trade BI Reports",
            onClick: () => {
              window.open("https://msit.powerbi.com/groups/me/apps/6f600dfa-208e-4023-908d-a6112896c0d5", '_blank')
            }
          },
        ]
      },
      {
        name: "Others",
        key: "leftNavTradeNet",
        ariaLabel: "Trade Hub",
        icon: "WaffleOffice365",
        url: null,
        links: [
          {
            name: "Trade Hub",
            key: "leftNavTradeNet",
            ariaLabel: "Trade Hub",
            onClick: () => {
              window.open("https://microsoft.sharepoint.com/teams/OneTrade", '_blank')
            }
          },
        ]
      }
    ]
  };
  return items;
}

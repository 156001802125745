import React, { useContext, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { InjectedIntlProps } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
import { getStyles } from './Sample.styles'
import { ApplicationContext } from '@msx/platform-services'
import { messages } from './Sample.messages';

interface OwnProps extends InjectedIntlProps {
  //TODO: any
}

type Props = OwnProps & RouteComponentProps;

const getClassNames = classNamesFunction<any, any>();
let classes: any;

const HomeContentComponent: (React.FC<Props>) = (props) => {
  const { intl } = props;
  const { appState  } = useContext(ApplicationContext);
  //const tileExtensions = extensionsRegistrationClient.getExtensionsTiles();

  classes = getClassNames(getStyles, appState.theme);

  useEffect(() => {
    // Placeholder
    // eslint-disable-next-line
  }, []);

  const renderMain = (): JSX.Element => {
    if (appState.appConfig.registrationConfig.active) return null;
    return (
      <div className={classes.aboutroot} >
        <p>
          {intl.formatMessage(messages.about1)}
        </p>
        <p>
          {intl.formatMessage(messages.about2)}
        </p>
        <p>
          {intl.formatMessage(messages.about3)}
        </p>
        <h4>{intl.formatMessage(messages.featureTitle)}</h4>
        <ol>
          <li>{intl.formatMessage(messages.feature1)}</li>
          <li>{intl.formatMessage(messages.feature2)}</li>
          <li>{intl.formatMessage(messages.feature3)}</li>
          <li>{intl.formatMessage(messages.feature4)}</li>
          <li>{intl.formatMessage(messages.feature5)}</li>
          <li>{intl.formatMessage(messages.feature6)}</li>
          <li>{intl.formatMessage(messages.feature7)}</li>
          <li>{intl.formatMessage(messages.feature8)}</li>
          <li>{intl.formatMessage(messages.feature9)}</li>
          <li>{intl.formatMessage(messages.feature10)}</li>
        </ol>
        <h4>{intl.formatMessage(messages.advanceFeatureTitle)}</h4>
        <ol>
          <li>{intl.formatMessage(messages.feature11)}</li>
          <li>{intl.formatMessage(messages.feature12)}</li>
          <li>{intl.formatMessage(messages.feature13)}</li>
          <li>{intl.formatMessage(messages.feature14)}</li>
          <li>{intl.formatMessage(messages.feature15)}</li>

        </ol>
      </div>
    );
  }

  return renderMain();

}

export const HomeContent = withRouter(injectIntl(HomeContentComponent));


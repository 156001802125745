
import { AxiosError } from 'axios';
import { IHttpClient, IHttpClientRequest, IPortalConfig } from '@msx/platform-services';
import { IExtensionMetadata } from '../../../models/IExtensionMetadata';
import { 
    CRUD_EXTENSIONMETADATA_LIST_BEGIN, 
    CRUD_EXTENSIONMETADATA_LIST_FAILURE, 
    CRUD_EXTENSIONMETADATA_LIST_SUCCESS,
    EXTENSION_METADATA_JSON_UPLOAD_BEGIN,
    EXTENSION_METADATA_JSON_UPLOAD_SUCCESS,
    EXTENSION_METADATA_JSON_UPLOAD_FAILURE 
} from '../..';
import { trackPromise } from 'react-promise-tracker';


export const crudExtensionMetadataListBegin = () => ({
  type: CRUD_EXTENSIONMETADATA_LIST_BEGIN
});

export const crudExtensionMetadataListSuccess = (payload: IExtensionMetadata) => ({
  type: CRUD_EXTENSIONMETADATA_LIST_SUCCESS,
  payload: payload
});

export const crudExtensionMetadataListError = (error: AxiosError) => ({
  type: CRUD_EXTENSIONMETADATA_LIST_FAILURE,
  payload: error
});

export const extensionMetadataJsonUploadBegin = () => ({
    type: EXTENSION_METADATA_JSON_UPLOAD_BEGIN
});

export const extensionMetadataJsonUploadSuccess = (payload: IExtensionMetadata) => ({
    type: EXTENSION_METADATA_JSON_UPLOAD_SUCCESS,
    payload: payload
});
  
export const extensionMetadataJsonUploadError = (error: AxiosError) => ({
    type: EXTENSION_METADATA_JSON_UPLOAD_FAILURE,
    payload: error
});

export const postExtensionMetadata = (httpClient: IHttpClient, extensionMetadataList: IExtensionMetadata[], appConfig:IPortalConfig) => {
    return async (dispatch: any) => {
        dispatch(crudExtensionMetadataListBegin());
        try {
            const resource = appConfig.registrationConfig.resource;
            const url = `${appConfig.registrationConfig.apiEndpoint}`;
            const request: IHttpClientRequest = {
                resource: resource,
                data: processExtensionMetadataSubmitList(extensionMetadataList)
            };
            const response: any = await trackPromise(httpClient.post(url, request));
            dispatch(crudExtensionMetadataListSuccess(response.data ? response.data : null));
        } catch (err) {
            dispatch(crudExtensionMetadataListError(err));
        }
        
    }
}

export const extensionMetadataJsonUpload = (httpClient: IHttpClient, jsonFileName: string, jsonText: string, appConfig:IPortalConfig) => {
    return async (dispatch: any) => {
        dispatch(extensionMetadataJsonUploadBegin());
        try {
            const resource = appConfig.registrationConfig.resource;
            const url = `${appConfig.registrationConfig.apiEndpoint}/Json`;
            const request: IHttpClientRequest = {
                resource: resource,
                data: { fileName: jsonFileName, json: jsonText }
            };
            const response: any = await trackPromise(httpClient.post(url, request));
            dispatch(extensionMetadataJsonUploadSuccess(response.data ? response.data : null));
          } catch (err) {
            dispatch(extensionMetadataJsonUploadError(err));
          }
    }
}

const processExtensionMetadataSubmitList = (extensionList) => {
    extensionList.forEach((item) => {
        if(typeof item['isActive'] != "boolean"){
            item['isActive'] = (item['isActive'].toLowerCase() == "true")
        }
      });
    return extensionList;
}
import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  subTitle: {
    id: 'signinPage.subTitle',
    defaultMessage: 'One-stop for all Trade business'
  },
  getStartedButtonText: {
    id: 'signinPage.getStartedButtonText',
    defaultMessage: "Sign in"
  },
  learnButtonText: {
    id: 'signinPage.learnButtonText',
    defaultMessage: 'Learn more'
  },
});


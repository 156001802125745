import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  headingMain: {
    id: `extension.headingMain`,
    defaultMessage: 'No Access',
  },
  subHeading: {
    id: `extension.subHeading`,
    defaultMessage: 'Looks like you do not have Read Access to this extension.',
  }
});

export default messages;




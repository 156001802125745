import { defineMessages } from "react-intl";

export const messages = defineMessages({
  home: {
    id: 'leftNavMenu.home',
    defaultMessage: 'Home'
  },
  externalLinksGroupTitle: {
    id: 'leftNavExtra.group.title',
    defaultMessage: 'Explore more'
  },
  leftNav_84d99b33_14df_4b75_b244_5410e153ebfa: {
    id: 'leftNav_84d99b33_14df_4b75_b244_5410e153ebfa',
    defaultMessage: 'Payments',
  },
  leftNav_0a7c9ffa_6e3b_4986_9539_9335e5e3d217: {
    id: 'leftNav_0a7c9ffa_6e3b_4986_9539_9335e5e3d217',
    defaultMessage: 'Purchase Order Monthly Projections',
  },
  leftNav_cb1bbd4f_2763_4048_af0a_97e82391be17: {
    id: 'leftNav_cb1bbd4f_2763_4048_af0a_97e82391be17',
    defaultMessage: 'Supplier Profile',
  },
  leftNav_566bc8d3_3195_406b_b603_10a660b759ee: {
    id: 'leftNav_566bc8d3_3195_406b_b603_10a660b759ee',
    defaultMessage: 'Call Center Invoice',
  },
  leftNav_37663ce7_32e5_44cd_85b7_29a83ca1cdc4: {
    id: 'leftNav_37663ce7_32e5_44cd_85b7_29a83ca1cdc4',
    defaultMessage: 'Purchase Orders',
  },
  leftNav_d5932e50_3cfb_4886_8490_9b099d180324: {
    id: 'leftNav_d5932e50_3cfb_4886_8490_9b099d180324',
    defaultMessage: 'Tickets',
  },
  leftNav_f167191b_cb15_40fd_ae15_74ba8b16c5d9: {
    id: 'leftNav_f167191b_cb15_40fd_ae15_74ba8b16c5d9',
    defaultMessage: 'Invoices',
  },
  leftNav_662873ce_09cd_4728_8527_242b32749601: {
    id: 'leftNav_662873ce_09cd_4728_8527_242b32749601',
    defaultMessage: 'Recycling',
  },
  leftNav_d2dabc9b_ae9a_4c8f_a41a_7cccdfaca805: {
    id: 'leftNav_d2dabc9b_ae9a_4c8f_a41a_7cccdfaca805',
    defaultMessage: 'User Management',
  },
  leftNav_cb1bbd4f_2763_4048_af0a_97e92391be17: {
    id: 'leftNav_cb1bbd4f_2763_4048_af0a_97e92391be17',
    defaultMessage: 'Supplier Profile',
  },
  leftNav_8b0d2bc8_d724_450a_8541_8ac91627e850: {
    id: 'leftNav_8b0d2bc8_d724_450a_8541_8ac91627e850',
    defaultMessage: 'Sourcing Events',
  },
});

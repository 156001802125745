import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { NavigationRouteProps, MsxExtension, ApplicationContext, ExtensionsRegistrationClient } from '@msx/platform-services'
import { getConfig } from './Extension.config';
import { ExtensionProps } from './Extension.types';
import { _shellStyles } from '../../../app/App.styles';
import { Link, MessageBar, MessageBarType, ScrollablePane } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { getExtensionsRegistration, getPBIAccessToken, getReportExtensionTiles, getExtnAttributes } from '../../store';
import { GetPBIToken } from '../../utils/pbitokenhelper';
import { AccessType, IAccessDetails, IAppExtensionExtended } from '../../../app/components/leftNav/IAppExtensionExtended';
import messages from './Extension.messages';

export const ExtensionComponent: (React.FC<ExtensionProps>) = props => {
  const { componentKey, isDashboard, intl } = props;
  const { appState } = useContext(ApplicationContext);
  const config = getConfig();
  const extensionsRegistration = useSelector(getExtensionsRegistration);
  //const attributes1 = useSelector(getExtnAttributes);
  const pbiAccessToken = useSelector(getPBIAccessToken);
  const reportTiles = useSelector(getReportExtensionTiles);
  const extensionsRegistrationClient = new ExtensionsRegistrationClient(extensionsRegistration);
  const [msgBarVisible, setMsgBarVisible] = useState<boolean>(true);

  const handleRouteNavigation = (route: NavigationRouteProps) => {
    props.history.push(route.location);
  }

  const handleLaunchFeedback = (extensionKey: string) => {
    (window as any).startMultiFeedback_AllOptional();
  }

  const handleLaunchChatBot = (extensionKey: string) => {
    (window as any).OpenFDA("hostappid=" + appState.appConfig.botConfig.hostAppId);
  }

  function getTileAttributes(key: string) {
    try {
      //GetPBIToken();
      let attributes0: any;
      if (reportTiles) {
        let currentTile = reportTiles.filter(t => t.key == key);
        if (currentTile) {
          attributes0 = currentTile[0]['attributes'];
        }
      }

      const attributes = {
        ...attributes0,
        accessToken: GetPBIToken(),
      }
      return attributes;
    }
    catch (ex) {

    }
  }

  const renderExtension = (): JSX.Element => {
    
    const attributes = getTileAttributes(props.componentKey);
    return (
      <MsxExtension
        componentKey={componentKey}
        config={config}
        attributes={attributes}
        onNavigateToRoute={handleRouteNavigation}
        onLaunchChatBot={handleLaunchChatBot}
        onLaunchFeedback={handleLaunchFeedback}
      />
    );
  }

  const renderRequestReadAccess = (accessDetails: IAccessDetails): JSX.Element => {
    return (
      <ScrollablePane className={appState.isNavCollapsed ? _shellStyles.scrollablePaneCollapsed : _shellStyles.scrollablePaneExpand}>
        <div className={appState.isNavCollapsed ? _shellStyles.mainPanelCollapsed : _shellStyles.mainPanelExpand}>
          <h1>{intl.formatMessage(messages.headingMain)}</h1>
          <h3>{accessDetails.primaryMessage} &nbsp;
            <Link href={`https://idwebelements.microsoft.com/GroupManagement.aspx?Group=${accessDetails.sGGroupAlias}&Operation=join`} target="_blank" underline>
              {accessDetails.linkMessage}
            </Link>
          </h3>
        </div>
      </ScrollablePane>
    )
  }

  const renderRequestOtherAccess = (accessDetailsArr: IAccessDetails[]): JSX.Element => {
    var MsgBarList: JSX.Element[] = [];
    accessDetailsArr.forEach(elem => {
      MsgBarList.push(<>
        <span>{elem.primaryMessage}</span>
        <Link href={`https://idwebelements.microsoft.com/GroupManagement.aspx?Group=${elem.sGGroupAlias}&Operation=join`} target="_blank" underline>
          {elem.linkMessage}
        </Link><br /></>)
    })

    return (
      <MessageBar dismissButtonAriaLabel="Close" onDismiss={() => { setMsgBarVisible(false) }}>
        {MsgBarList}
      </MessageBar>
    );
  }

  const renderMain = (): JSX.Element => {
    if (isDashboard) return renderExtension();
    var extensions: IAppExtensionExtended[] = extensionsRegistrationClient.getExtensions() as IAppExtensionExtended[];
    var ext = extensions.filter(x => x.pages.filter(y => y.key == componentKey).length > 0);
    if (ext.length > 0 && !ext[0].authorized) {
      return renderRequestReadAccess(ext[0].accessDetails[0]);
    }

    return (
      <ScrollablePane className={appState.isNavCollapsed ? _shellStyles.scrollablePaneCollapsed : _shellStyles.scrollablePaneExpand}>
        {
          (ext.length > 0 && ext[0].authorized && ext[0].accessDetails != null && ext[0].accessDetails.length > 0 && msgBarVisible)
            ?
            renderRequestOtherAccess(ext[0].accessDetails)
            :
            null
        }
        {renderExtension()}
      </ScrollablePane>
    );
  }

  return renderMain();

}
export const Extension = withRouter(injectIntl(ExtensionComponent));

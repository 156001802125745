import { AnyAction } from 'redux';
import {
  FETCH_DASHBOARD_VISUALS_CONFIG_BEGIN,
  FETCH_DASHBOARD_VISUALS_CONFIG_SUCCESS,
  FETCH_DASHBOARD_VISUALS_CONFIG_FAILURE,
  ReduxDashboardVisualsConfigState
} from '../..';

const dashboardVisualsConfigInitialState: ReduxDashboardVisualsConfigState = {
  DashboardVisualsConfig: null,
  isLoading: true,
  error: null,
};

export default function dashboardVisualsConfigReducer(
  state: ReduxDashboardVisualsConfigState = dashboardVisualsConfigInitialState,
  action: AnyAction
) {
  switch (action.type) {
    case FETCH_DASHBOARD_VISUALS_CONFIG_BEGIN:
      return {
        ...state,
        isLoading: true,
        error: null,
        DashboardVisualsConfig: null
      };
    case FETCH_DASHBOARD_VISUALS_CONFIG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        DashboardVisualsConfig: action.payload
      };
    case FETCH_DASHBOARD_VISUALS_CONFIG_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
}

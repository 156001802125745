import { AnyAction } from 'redux';
import {
  FETCH_EXTENSIONMETADATA_LIST_BEGIN,
  FETCH_EXTENSIONMETADATA_LIST_SUCCESS,
  FETCH_EXTENSIONMETADATA_LIST_FAILURE,
  ReduxExtensionMetadataState
} from '../..';

const extensionMetadataListInitialState: ReduxExtensionMetadataState = {
  extensionMetadataList: null,
  isLoading: true,
  error: null,
};

export default function extensionMetadataListReducer(
  state: ReduxExtensionMetadataState = extensionMetadataListInitialState,
  action: AnyAction
) {
  switch (action.type) {
    case FETCH_EXTENSIONMETADATA_LIST_BEGIN:
      return {
        ...state,
        isLoading: true,
        error: null,
        extensionMetadataList: null
      };
    case FETCH_EXTENSIONMETADATA_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        extensionMetadataList: action.payload
      };
    case FETCH_EXTENSIONMETADATA_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
}

import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  pageTile: {
    id: 'adminPage.title',
    defaultMessage: 'Admin Page'
  },
  noDataFound: {
    id: 'adminPage.noDataFound',
    defaultMessage: 'Currently, there are no extensions availiable.',
  },
  success: {
    id: 'jsonTextModal.success',
    defaultMessage: 'Json Upload Success'
  },
  error: {
    id: 'jsonTextModal.error',
    defaultMessage: 'Json Upload Error'
  }
});


import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import {
  IStyle, mergeStyleSets, ActionButton,
  IIconProps, Toggle
} from 'office-ui-fabric-react';
import { InjectedIntlProps } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { messages } from './Sample.messages';
import { IAppTheme } from '@msx/platform-services';


const SettingsStyles = mergeStyleSets({
  root: {
    marginLeft: '-10px',
    marginRight: '-10px'
  } as IStyle,
  toggle: {
    marginTop: '3px',
    marginLeft: '10px'
  } as IStyle
});


const settingsIcon: IIconProps = { iconName: 'Settings' };

const ringerIcon: IIconProps = { iconName: 'Ringer' };

const Volume2Icon: IIconProps = { iconName: 'Volume2' };

const FavoriteStarIcon: IIconProps = { iconName: 'FavoriteStar' };

const TouchIcon: IIconProps = { iconName: 'Touch' };

interface OwnProps extends InjectedIntlProps {
  isAppReady: boolean;
  theme: IAppTheme;
}

type SettingsProps = OwnProps & RouteComponentProps;

const Settings: (React.FC<SettingsProps>) = (props) => {
  const { intl } = props;

  useEffect(() => {
    // Placeholder
    // eslint-disable-next-line
  }, []);

  const renderMain = (): JSX.Element => {
    return (
      <div className={SettingsStyles.root}>
        <ActionButton iconProps={settingsIcon} >
          {intl.formatMessage(messages.settingsPageLinkGeneral)}
        </ActionButton>
        <br />
        <ActionButton iconProps={ringerIcon}>
          {intl.formatMessage(messages.settingsPageLinkNotifications)}
        </ActionButton>
        <br />
        <ActionButton iconProps={Volume2Icon}>
          {intl.formatMessage(messages.settingsPageLinkSounds)}
        </ActionButton>
        <br />
        <ActionButton iconProps={FavoriteStarIcon}>
          {intl.formatMessage(messages.settingsPageLinkFavorites)}
        </ActionButton>
        <br />
        <ActionButton iconProps={TouchIcon}>
          {intl.formatMessage(messages.settingsPageLinkAccessibility)}
        </ActionButton>
        <Toggle className={SettingsStyles.toggle} label={intl.formatMessage(messages.settingsPageLinkFabricComponent)} />
      </div>
    );
  }

  return renderMain();

}
export const SettingsExample = withRouter(injectIntl(Settings));



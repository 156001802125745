import React, { useState, useEffect, useContext, useRef } from 'react';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { IconButton, PrimaryButton, Stack, Link, ScrollablePane } from 'office-ui-fabric-react';
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { ServiceContext, ApplicationContext } from '@msx/platform-services';
import { Table, MsxModal, MsxModalHeight } from '@msx/react-ui-component'
import { PageError } from '../../../core/components';
import { getCurrentTheme } from '../../../core/store';
import { HTTP_STATUS_NOT_FOUND } from '../../App.types';
import { IToDo } from '../../models/IToDo';
import { messages } from './ToDoList.messages';
import { getStyles } from './ToDoList.styles';
import {
  getToDoList, getToDoListLoadingStatus, getToDoListError,
  fetchToDoList, addToDo, editToDo, deleteToDo
} from '../../store';
import { UpdateToDo } from './UpdateToDo';
import { _shellStyles } from '../../App.styles';

const getClassNames = classNamesFunction<any, any>();
let classes: any;

interface OwnProps extends InjectedIntlProps {
  //TODO;
}

type ToDoListProps = OwnProps & InjectedIntlProps;

const ToDoListComponent: React.FC<ToDoListProps> = props => {
  const { intl } = props;
  const { appState } = useContext(ApplicationContext);
  const reduxDispatch = useDispatch();
  const toDoListLoadingStatus = useSelector(getToDoListLoadingStatus);
  const toDoListLoadingError = useSelector(getToDoListError);
  const todoList: IToDo[] = useSelector(getToDoList);
  const [tasks, setTasks] = useState(todoList);
  const theme = useSelector(getCurrentTheme);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [currentToDo, setCurrentToDo] = useState<IToDo>(null);
  const { httpClient } = useContext(ServiceContext);
  const tasksRef = useRef(tasks);
  classes = getClassNames(getStyles, appState);

  useEffect(() => {
    reduxDispatch(fetchToDoList(httpClient, appState.appConfig));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTasks(todoList);
    // eslint-disable-next-line
  }, [todoList]);


  const getTableTitle = (): string => {
    return null;
  }

  const handleAddToDoClick = () => {
    setCurrentToDo(null);
    setShowDetails(true);
  }
  const handleToDoIdClick = (todo: IToDo) => {
    setCurrentToDo(todo);
    setShowDetails(true);
  }
  const handleDeleteClick = (todo: IToDo) => {
    reduxDispatch(deleteToDo(httpClient, todo, appState.appConfig));
    const newTodos = tasksRef.current.filter((item, index) => {
      return item.id !== todo.id;
    });
    setTasks(newTodos);
  }

  const closeDetails = (): void => {
    setShowDetails(false);
  }

  const renderToDoId = (todo: IToDo, index: number): JSX.Element => {
    const todoDeleteLinkId: string = 'ToDoDeleteId' + index;
    return (
      <Stack horizontal >
        <TooltipHost
          content="Delete"
          id={todoDeleteLinkId}
          tooltipProps={{ style: { overflowY: 'auto', maxWidth: '160px' } }}
        >
          <IconButton
            iconProps={{ iconName: "Delete" }}
            ariaLabel='Delete todo task'
            className={classes.deleteButtonLink}
            onClick={() => handleDeleteClick(todo)}
            onKeyDown={(e) => e.keyCode === 13 || e.keyCode === 32 ? handleDeleteClick(todo) : null}
            styles={{
              root: { width: '30%', height: '80%' },
            }}
          />
        </TooltipHost>
      </Stack>);
  }

  const renderToDoTitle = (todo: IToDo, index: number): JSX.Element => {
    const id: string = 'ToDoTitle' + index;
    return (
      <Link id={id}
        onClick={() => handleToDoIdClick(todo)}
      >
        {todo.title}
      </Link>
    );
  }

  const columns = [
    {
      key: 'keyTodoId',
      fieldName: 'id',
      name: props.intl.formatMessage(messages.colId),
      onRender: renderToDoId,
    },
    {
      key: 'keyTodoTitle',
      fieldName: 'title',
      name: props.intl.formatMessage(messages.colTitle),
      onRender: renderToDoTitle,
      minWidth: 250,
      maxWidth: 250,
    },
    {
      key: 'keyTodoOwner',
      fieldName: 'owner',
      name: props.intl.formatMessage(messages.colOwner),
      minWidth: 200,
      maxWidth: 200,
    },
  ];

  const handleSubmitClick = (todo: IToDo): void => {
    if (currentToDo) {
      reduxDispatch(editToDo(httpClient, todo, appState.appConfig));
      const newTodos = [...tasks];
      newTodos.forEach((item, index) => {
        if (item.id === todo.id) {
          item.title = todo.title;
        }
      });
      setTasks(newTodos);
    }
    else {
      reduxDispatch(addToDo(httpClient, todo, appState.appConfig));
      setTasks([...tasks, todo]);
    }
    setShowDetails(false);
  }

  const renderDetails = () => {
    if (showDetails) {
      const title = `${currentToDo ? intl.formatMessage(messages.addTaskTitle) : intl.formatMessage(messages.editTaskTitle)}`;
      return (
        <MsxModal
          title={title}
          height={MsxModalHeight.Max}
          onDismiss={closeDetails}
          closeButtonTooltipText={intl.formatMessage(messages.modalCloseTitle)}
          theme={theme}
        >
          <UpdateToDo
            toDo={currentToDo}
            onSubmitClick={handleSubmitClick}
          />
        </MsxModal>
      );
    }
  }

  const exportCsvProps = {
    canExportCsv: false,
    csvFilename: 'todos.csv',
    // csvHeaders,
    // csvTelemetryProps,
    id: 'todosExportToCsv',
    exportButtonLabel: props.intl.formatMessage(messages.exportToCSV)
  }

  const renderBodyComponent = () => {
    if (toDoListLoadingError) {
      return renderDataLoadingErrorComponent();
    }
    const rows = tasks ? tasks : [];
    return (
      <div>
        <Table
          isLoading={toDoListLoadingStatus}
          exportCsvProps={exportCsvProps}
          columns={columns}
          rows={rows}
          tableTitle={getTableTitle()}
          theme={theme}
        />
        <br />
        <PrimaryButton text={intl.formatMessage(messages.addNewButtonText)} onClick={handleAddToDoClick} />
      </div>
    );

  }
  const renderNoDataComponent = () => {
    return (
      <p>{props.intl.formatMessage(messages.noDataFound)}</p>
    );
  }

  const renderDataLoadingErrorComponent = () => {
    if (toDoListLoadingError.response && toDoListLoadingError.response.status === HTTP_STATUS_NOT_FOUND) {
      return renderNoDataComponent();
    }
    return <PageError error={toDoListLoadingError} />
  }

  const renderMain = (): JSX.Element => {

    tasksRef.current = tasks;
    return (
      <ScrollablePane className={appState.isNavCollapsed ? _shellStyles.scrollablePaneCollapsed : _shellStyles.scrollablePaneExpand}>
        <div className={appState.isNavCollapsed ? _shellStyles.mainPanelCollapsed : _shellStyles.mainPanelExpand}>
          <h1 className={classes.headingMain}>{intl.formatMessage(messages.pageTile)}</h1>
          {renderBodyComponent()}
          {renderDetails()}
        </div>
      </ScrollablePane>
    )
  }
  return renderMain();
}

export const ToDoList = injectIntl(ToDoListComponent);

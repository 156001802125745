import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  pageTile: {
    id: 'homePage.title',
    defaultMessage: 'Home'
  },
  loadingData: {
    id: 'homePage.loadingData',
    defaultMessage: 'Loading extensions metadata, please wait...'
  },
  noDataFound: {
    id: 'homePage.noDataFound',
    defaultMessage: 'Currently, there are no extensions availiable.',
  },

});


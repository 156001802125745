import React, { useEffect, useContext, useState } from 'react';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
import { Stack, ScrollablePane, DetailsListLayoutMode, SelectionMode, Pivot, PivotLinkSize, PivotItem, PrimaryButton, TextField, ITextFieldStyles, Dialog, Modal, DefaultButton, Label } from '@fluentui/react';
import { ServiceContext, ApplicationContext } from '@msx/platform-services';
import { EditableGrid, EditControlType, IColumnConfig, EventEmitter, EventType, NumberAndDateOperators } from 'fluentui-editable-grid';
import {
    IHttpClient,
    IHttpClientRequest,
    IExtensionsRegistration,
    IAppExtension,
    IKeyValueItem,
    IPortalConfig
} from '@msx/platform-services';
import { trackPromise } from 'react-promise-tracker';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { getCurrentTheme, getExtensionsError } from '../../../../core/store';
import { getStyles } from '../AdminPage.styles';
import { _shellStyles } from '../../../App.styles';
import { useId, useBoolean } from '@fluentui/react-hooks';
import { contentStyles, gapStackTokens, stackStyles } from './JsonTextModal.styles';
import { messages } from './JsonTextModal.messages';
import { extensionMetadataJsonUpload, getExtensionMetadataJsonUploadError, getExtensionMetadataJsonUploadLoadingStatus } from '../../../store';
import { LoadingIndicator } from '../../../../core/components/shared/loadingindicator';
import { ToastContainer, toast } from "react-toastify";

const getClassNames = classNamesFunction<any, any>();
let classes: any;

interface OwnProps extends InjectedIntlProps {
  //TODO: any
  hidden: boolean;
  onModalDismiss: any;
}

type JsonTextModalProps = OwnProps & InjectedIntlProps;

interface JSONTextFieldProps {
    borderColor : string;
    borderWidth : string;
}

const JsonTextModalComponent: React.FC<JsonTextModalProps> = props => {
  const { intl } = props;
  const theme = useSelector(getCurrentTheme);
  const { httpClient } = useContext(ServiceContext);
  const extensionsError = useSelector(getExtensionsError);
  const { appState } = useContext(ApplicationContext)
  const reduxDispatch = useDispatch();
  const appInsights = useAppInsightsContext();
  const [isJSONValid, setIsJSONValid] = useState<boolean>(false);
  const [json, setJson] = useState('');
  const [jsonTextFieldAttr, setJsonTextFieldAttr] = useState<JSONTextFieldProps>({ borderColor : 'none', borderWidth : '0px' });
  const [fileNameText, setFileNameText] = useState('');
  const extensionMetadataJsonUploadLoadingStatus = useSelector(getExtensionMetadataJsonUploadLoadingStatus);
  const extensionMetadataJsonUploadError = useSelector(getExtensionMetadataJsonUploadError);

  useEffect(() => {
    if(!extensionMetadataJsonUploadLoadingStatus && !extensionMetadataJsonUploadError && !props.hidden){
      onModalDismiss('Json Uploaded successfully');
    }
  }, [extensionMetadataJsonUploadLoadingStatus]);

  useEffect(() => {
    if(extensionMetadataJsonUploadError){
      onModalDismiss('Json Uploaded error');
    }
  }, [extensionMetadataJsonUploadError]);

  classes = getClassNames(getStyles, theme);
  var multiLineTextFieldStyle: Partial<ITextFieldStyles> = { fieldGroup: { width: '95%', borderColor:jsonTextFieldAttr.borderColor, borderWidth:jsonTextFieldAttr.borderWidth, margin:'10px 10px 10px 0' } };

  const onJSONChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) : void =>{
    if(newValue.trim() == ''){
        setJsonTextFieldAttr({ borderColor : 'none', borderWidth : '0px' });
    }
    else{
        ValidateJSON(newValue);
    }
    setJson(newValue);
  }

  const ValidateJSON = (value : string) : void => {
    try{
        JSON.parse(value);
        setJsonTextFieldAttr({ borderColor : '#8BC34A', borderWidth : '4px' });
        setIsJSONValid(true);
    }
    catch (e){
        setJsonTextFieldAttr({ borderColor : 'red', borderWidth : '4px' });
        setIsJSONValid(false);
    }
  }

  const onFilenameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) : void =>{
    setFileNameText(newValue);
  }

  const onUploadButtonClick = () : void => {
    var fileName = fileNameText.toLowerCase().replace('.json', '') + '.json';
    reduxDispatch(extensionMetadataJsonUpload(httpClient, fileName, json, appState.appConfig));
  }

  const onModalDismiss = (text: string) : void => {
    setJson('');
    setFileNameText('');
    props.onModalDismiss(text);
  }

  const renderBodyComponent = () => {
    return (
      <Stack grow verticalAlign="space-between" styles={stackStyles}>
        <Stack.Item>
          <Stack horizontal>
            <TextField placeholder='Filename' value={fileNameText} onChange={onFilenameChange} />
            <Label>&nbsp;.json</Label>
          </Stack>
        </Stack.Item>
        <Stack.Item grow={1}>
          <ScrollablePane style={{ position: 'relative', height: '45vh', width:'100%' }}>
              <TextField 
                  multiline autoAdjustHeight 
                  styles={multiLineTextFieldStyle}
                  rows={18}
                  onChange={onJSONChange}
                  placeholder='Input JSON text...'
                  value={json}
              />
          </ScrollablePane>
        </Stack.Item>
        <Stack.Item>
            <Stack verticalAlign="end" horizontal tokens={gapStackTokens}>
                <PrimaryButton text="Upload" disabled={!isJSONValid} checked={true} onClick={onUploadButtonClick} />
                <DefaultButton text="Cancel" checked={true} onClick={() => onModalDismiss('')} />
            </Stack>
        </Stack.Item>
      </Stack>
    );
  }

  const renderMain = (): JSX.Element => {
    return (
      <Modal 
        titleAriaId={"title"}
        isOpen={!props.hidden}
        onDismiss={() => onModalDismiss('')}
        isBlocking={false}
        containerClassName={contentStyles.container}
      > 
        <div>
            <LoadingIndicator />
        </div>
        <div style={{height:'100%'}}>
          <h1 className={classes.headingMain}>{intl.formatMessage(messages.modalTitle)}</h1>
          <br/>
          {renderBodyComponent()}
        </div>
      </Modal>
    )
  }
  return renderMain();
}

export const JsonTextModal = injectIntl(JsonTextModalComponent);

import { createSelector } from 'reselect';
import { ReduxRootState, ReduxExtensionMetadataState, } from '../..';
import { IExtensionMetadata } from '../../../models/IExtensionMetadata';
import { AxiosError } from 'axios';

const extensionMetadataListState = (state: ReduxRootState): ReduxExtensionMetadataState => state.extensionMetadataList;

export const getExtensionMetadataList = createSelector(
  extensionMetadataListState,
  (extensionMetadataListState: ReduxExtensionMetadataState): IExtensionMetadata[] => extensionMetadataListState.extensionMetadataList
);

export const getExtensionMetadataListLoadingStatus = createSelector(
  extensionMetadataListState,
  (extensionMetadataListState: ReduxExtensionMetadataState): boolean => extensionMetadataListState.isLoading
);

export const getExtensionMetadataListError = createSelector(
  extensionMetadataListState,
  (extensionMetadataListState: ReduxExtensionMetadataState): AxiosError => extensionMetadataListState.error
);


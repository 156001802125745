import { AnyAction } from 'redux';
import {
  FETCH_TODO_LIST_BEGIN,
  FETCH_TODO_LIST_SUCCESS,
  FETCH_TODO_LIST_FAILURE,
  ReduxToDoListState
} from '..';

const todoListInitialState: ReduxToDoListState = {
  todoList: null,
  isLoading: true,
  error: null,
};

export default function todoListReducer(
  state: ReduxToDoListState = todoListInitialState,
  action: AnyAction
) {
  switch (action.type) {
    case FETCH_TODO_LIST_BEGIN:
      return {
        ...state,
        isLoading: true,
        error: null,
        todoList: null
      };
    case FETCH_TODO_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        todoList: action.payload
      };
    case FETCH_TODO_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
}

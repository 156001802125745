import { combineReducers } from 'redux';
import { app, userProfile, extensionsRegistration } from '../../../core/store';
import todoList from './todoList.reducer';
import todoCrud from './todoCrud.reducer';
import extensionMetadataList from './adminPage/extensionMetadataList.reducer';
import extensionMetadataCrud from './adminPage/extensionMetadataCrud.reducer';
import extensionMetadataJsonUpload from './adminPage/extensionMetadataJsonUpload.reducer';
import dashboardVisualsConfig from './dashboardVisuals/dashboardvisualsconfig.reducer';
import dashboardVisualsConfigSave from './dashboardVisuals/saveDashboardVisualsConfig.reducer';

export const reducers = combineReducers({
  app,
  extensionsRegistration,
  userProfile,
  todoList,
  todoCrud,
  extensionMetadataList,
  extensionMetadataCrud,
  extensionMetadataJsonUpload,
  dashboardVisualsConfig,
  dashboardVisualsConfigSave
});


import React, { useEffect, useContext, useState } from 'react';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
import { fetchExtensionsRegistration, getCurrentTheme, getExtensionsError, getIsDevLogIn } from '../../../core/store';
import { DashboardEdit, PageError } from '../../../core/components';
import { HTTP_STATUS_NOT_FOUND } from '../../App.types';
import { messages } from './AdminPage.messages';
import { getStyles } from './AdminPage.styles';
import { DashboardContent } from '../../components/sample';
import { Stack, ScrollablePane, DetailsListLayoutMode, SelectionMode, Pivot, PivotLinkSize, PivotItem, PrimaryButton } from '@fluentui/react';
import { _shellStyles } from '../../App.styles';
import { ServiceContext, ApplicationContext } from '@msx/platform-services';
import { EditableGrid, EditControlType, IColumnConfig, EventEmitter, EventType, NumberAndDateOperators } from 'fluentui-editable-grid';
import { appConfig } from '../../App.config';
import {
  IHttpClient,
  IHttpClientRequest,
  IExtensionsRegistration,
  IAppExtension,
  IKeyValueItem,
  IPortalConfig
} from '@msx/platform-services';
import { trackPromise } from 'react-promise-tracker';
import { getExtensionMetadataList, getExtensionMetadataListError, getExtensionMetadataListLoadingStatus } from '../../store/selectors/adminPage/extensionMetadata.selectors';
import { IExtensionMetadata } from '../../models/IExtensionMetadata';
import { fetchExtensionMetadataList } from '../../store/actions/adminPage/extensionsMetadataList.actions';
import { Operation } from 'fluentui-editable-grid/dist/types/operation';
import { extensionMetadataJsonUpload, postExtensionMetadata } from '../../store/actions/adminPage/extensionMetadataCrud.actions';
import { getExtensionMetadataCrudError, getExtensionMetadataCrudLoadingStatus } from '../../store/selectors/adminPage/extensionMetadataCrud.selectors';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { LoadingIndicator } from '../../../core/components/shared/loadingindicator';
import { JsonTextModal } from './jsonTextModal/JsonTextModal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const getClassNames = classNamesFunction<any, any>();
let classes: any;

interface OwnProps extends InjectedIntlProps {
  //TODO: any
}

const GridColumnConfig: IColumnConfig[] =
  [
    {
      key: 'id',
      name: 'ID',
      text: 'ID',
      editable: false,
      dataType: 'number',
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      includeColumnInExport: true,
      includeColumnInSearch: true,
      applyColumnFilter: true
    },
    {
      key: 'groupId',
      name: 'Group ID',
      text: 'Group ID',
      editable: true,
      dataType: 'string',
      minWidth: 220,
      maxWidth: 220,
      isResizable: true,
      includeColumnInExport: true,
      includeColumnInSearch: true,
      applyColumnFilter: true
    },
    {
      key: 'groupName',
      name: 'Group Name',
      text: 'Group Name',
      editable: true,
      dataType: 'string',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      includeColumnInExport: true,
      includeColumnInSearch: true,
      applyColumnFilter: true
    },
    {
      key: 'jsonBlobUrl',
      name: 'JSON Blob URL',
      text: 'JSON Blob URL',
      editable: true,
      dataType: 'string',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      includeColumnInExport: true,
      includeColumnInSearch: true,
      applyColumnFilter: true,
      inputType: EditControlType.MultilineTextField,
    },
    {
      key: 'blobFileName',
      name: 'Blob File Name',
      text: 'Blob File Name',
      editable: true,
      dataType: 'string',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      includeColumnInExport: true,
      includeColumnInSearch: true,
      applyColumnFilter: true
    },
    {
      key: 'sasTokenKey',
      name: 'SAS Token Key',
      text: 'SAS Token Key',
      editable: true,
      dataType: 'string',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      includeColumnInExport: true,
      includeColumnInSearch: true,
      applyColumnFilter: true
    },
    {
      key: 'isActive',
      name: 'Is Active',
      text: 'Is Active',
      editable: true,
      dataType: 'string',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      includeColumnInExport: true,
      includeColumnInSearch: true,
      applyColumnFilter: true,
      inputType: EditControlType.DropDown,
      dropdownValues: [
        { key: 'True', text: 'True' },
        { key: 'False', text: 'False' }
      ]
    },
    {
      key: 'displayOrder',
      name: 'Display Order',
      text: 'Display Order',
      editable: true,
      dataType: 'number',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      includeColumnInExport: true,
      includeColumnInSearch: true,
      applyColumnFilter: true
    },
    {
      key: 'accessKeyId',
      name: 'accessKeyId',
      text: 'Access Key Id',
      editable: true,
      dataType: 'number',
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      includeColumnInExport: true,
      includeColumnInSearch: true,
      applyColumnFilter: true
    },
  ]

type AdminPageProps = OwnProps & InjectedIntlProps;

const AdminPageComponent: React.FC<AdminPageProps> = props => {
  const { intl } = props;
  const theme = useSelector(getCurrentTheme);
  const { httpClient } = useContext(ServiceContext);
  const extensionsError = useSelector(getExtensionsError);
  const { appState } = useContext(ApplicationContext)
  const reduxDispatch = useDispatch();
  const extensionMetadataList: IExtensionMetadata[] = useSelector(getExtensionMetadataList);
  const extensionMetadataListLoadingStatus = useSelector(getExtensionMetadataListLoadingStatus);
  const extensionMetadataListLoadingError = useSelector(getExtensionMetadataListError);
  const extensionMetadataCrudLoadingStatus = useSelector(getExtensionMetadataCrudLoadingStatus);
  const extensionMetadataCrudError = useSelector(getExtensionMetadataCrudError);
  const [items, setItems] = useState(extensionMetadataList);
  const appInsights = useAppInsightsContext();
  const [jsonTextModalDialogObj, setJsonTextModalDialogObj] = React.useState({
    visible: false
  });

  useEffect(() => {
    fetchExtensionsList();
  }, [httpClient]);

  useEffect(() => {
    if (!extensionMetadataCrudLoadingStatus) {
      fetchExtensionsList();
      reduxDispatch(fetchExtensionsRegistration(httpClient, [], appConfig, appInsights, true));
    }
  }, [extensionMetadataCrudLoadingStatus]);

  useEffect(() => {
    setItems(extensionMetadataList);
  }, [extensionMetadataList]);

  classes = getClassNames(getStyles, theme);

  function fetchExtensionsList() {
    reduxDispatch(fetchExtensionMetadataList(httpClient, appState.appConfig));
  }

  const onGridSave = (data: any[]): void => {
    console.log(data);
    if (data.filter(x => x._grid_row_operation_ != Operation.None).length > 0) {
      reduxDispatch(postExtensionMetadata(httpClient, data, appState.appConfig));
    }
  };

  const onUploadClick = (): void => {
    setJsonTextModalDialogObj({ visible: true });
  }

  const onModalDismiss = (text: string): void => {
    setJsonTextModalDialogObj({ visible: false });
    if (text.toLowerCase().indexOf('success') > -1) {
      toast.success(intl.formatMessage(messages.success), {
        position: toast.POSITION.TOP_CENTER
      });
    }
    else if (text.toLowerCase().indexOf('error') > -1) {
      toast.error(intl.formatMessage(messages.error), {
        position: toast.POSITION.TOP_CENTER
      });
    }

  }

  const renderNoDataComponent = () => {
    return (
      <p>{props.intl.formatMessage(messages.noDataFound)}</p>
    );
  }

  const renderDataLoadingErrorComponent = () => {
    if (extensionMetadataListLoadingError.response && extensionMetadataListLoadingError.response.status === HTTP_STATUS_NOT_FOUND) {
      return renderNoDataComponent();
    }
    return <PageError error={extensionMetadataListLoadingError} />
  }

  const renderBodyComponent = () => {
    if (extensionMetadataListLoadingError) {
      return renderDataLoadingErrorComponent();
    }
    return (
      <Stack>
        <div>
          <LoadingIndicator />
        </div>
        <ToastContainer />
        <Pivot
          aria-label="Admin Page"
          linkSize={PivotLinkSize.large}
          //onLinkClick={onTabChange}
          selectedKey={'VisualConfig'}
        >
          <PivotItem headerText="Metadata Config" itemKey="Metadata">
            <EditableGrid
              id={1}
              //enableColumnEdit={true}
              enableSave={true}
              columns={GridColumnConfig}
              layoutMode={DetailsListLayoutMode.justified}
              selectionMode={SelectionMode.multiple}
              enableRowEdit={true}
              enableRowEditCancel={true}
              //enableBulkEdit={true}
              items={items}
              enableCellEdit={true}
              //enableExport={true}
              enableTextFieldEditMode={true}
              enableTextFieldEditModeCancel={true}
              enableGridRowsDelete={true}
              //enableGridRowsAdd={true}
              height={"max(calc(80vh - 300px),100px)"}
              width={"max(calc(100vw - 300px),400px)"}
              position={'relative'}
              enableUnsavedEditIndicator={true}
              onGridSave={onGridSave}
              enableGridReset={true}
              //enableColumnFilters={true}
              //enableColumnFilterRules={true}
              enableRowAddWithValues={{ enable: true, enableRowsCounterInPanel: true }}
              enableSingleClickCellEdit={true}
            />

            <PrimaryButton
              text="Upload Json"
              id="uploadjson"
              onClick={onUploadClick}
              ariaLabel="Upload Json"
              style={{ margin: '20px 0px 0px 0px' }}
            />


            <JsonTextModal
              hidden={!jsonTextModalDialogObj.visible}
              onModalDismiss={onModalDismiss}
            />
          </PivotItem>
        </Pivot>
      </Stack>
    );
  }

  const renderMain = (): JSX.Element => {
    return (
      <ScrollablePane className={appState.isNavCollapsed ? _shellStyles.scrollablePaneCollapsed : _shellStyles.scrollablePaneExpand}>
        <div className={appState.isNavCollapsed ? _shellStyles.mainPanelCollapsed : _shellStyles.mainPanelExpand}>
          <h1 className={classes.headingMain}>{intl.formatMessage(messages.pageTile)}</h1>
          {renderBodyComponent()}
        </div>
      </ScrollablePane>
    )
  }
  return renderMain();
}

export const AdminPage = injectIntl(AdminPageComponent);

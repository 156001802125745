import { InjectedIntl } from 'react-intl';
import { messages } from './AppLeftNav.messages';
import { IAppExtension, IAppExtensionPage, IAppRoute } from '@msx/platform-services';
import {getLeftNavMenuItems} from '../../App.navigation';
import { getExtraLeftNavMenuItems } from '../../App.navigation';
import { IAppExtensionPageExtended } from './IAppExtensionPageExtended';
import { IAppExtensionExtended } from './IAppExtensionExtended';


export const getConfig = (intl: InjectedIntl, isAppReady: boolean,
  history: any, extensionsPages: IAppExtensionPageExtended[],
  isUserLoggedIn: boolean,
  routes: IAppRoute[],
  isDevLogIn: boolean,
  extensions:IAppExtensionExtended[]) => {

  if (!isAppReady)
    return []

  const navLinkGroups = getMenuItems(intl, isAppReady, history, extensionsPages, isUserLoggedIn, routes, isDevLogIn, extensions);
  
  //creating external links
  const extraLeftNavLinks = getExtraLeftNavMenuItems(intl, history);
  extraLeftNavLinks.links.forEach((links) => {
    navLinkGroups[1].links.push({
      name: links.name,
      key: links.key,
      ariaLabel: links.ariaLabel,
      icon: links.icon,
      onClick: links.url ? () => {
        window.open(links.url, '_blank')
      } : null,
      links: links.links,
      isSelected: window.location.href === window.location.protocol + '//' + window.location.host + `` ? true : false
    })
  });


  return navLinkGroups

};

const getMenuItems = (intl: InjectedIntl, isAppReady: boolean, history: any,
  extensionsPages: IAppExtensionPage[], isUserLoggedIn: boolean, routes: IAppRoute[], isDevLogIn: boolean,
  extensions:IAppExtensionExtended[]) => {
  if (!isAppReady)
    return []

  const getlocaleText = (key: string, defaultValue: string) => {
    let result = defaultValue;
    const newKey = 'leftNav_' + key.replace(/-/g, '_');
    const descriptor = messages[newKey];
    if (descriptor) result = intl.formatMessage(descriptor);
    return result;
  };

  const getNavObjectForPage = (page : IAppExtensionPageExtended, extension : IAppExtensionExtended) : any => {
    if(page.routeKey && !page.routePath && routes.filter(x => x.key == page.routeKey).length > 0){
      page.routePath = routes.filter(x => x.key == page.routeKey)[0].routePath;
    }
    const navObj : any = {};
    navObj.name = getlocaleText(page.key, page.description);
    navObj.key = page.key;
    navObj.ariaLabel = page.description;
    navObj.icon = extension.authorized ? page.icon : 'BlockedSiteSolid12';
    navObj.onClick = () => {
      return page.routePath?history.push({ pathname: `/${page.routePath}` }):null;
    };
    navObj.isSelected = window.location.href ===
    window.location.protocol +
    '//' +
    window.location.host +
    `${page.routePath}`
    ? true
    : false;

    if(page.pages && page.pages.length > 0){
      navObj.links = [];
      page.pages.forEach((subPage) => {
        navObj.links.push(getNavObjectForPage(subPage, extension));
      })
    }
    return navObj;
  }

  const navMenuLinks =
    [
      // creating first left navigation menu group
      // to display all app pages links
      {
        key: 'left_nav_menu',
        groupTitleAttributes: {
          id: 'left_nav_group_menu',
        },
        links: [
        ],
      },
      // creating second left navigation menu group
      // to display all external pages links
      // to change any external links open AppLeftNavExtraLinks.ts
      {
        key: 'left_nav_external_links',
        name: intl.formatMessage(messages.externalLinksGroupTitle),
        groupTitleAttributes: {
          id: 'left_nav_group_external_links',
        },
        links: [
        ],
      },
    ];
  // creating home page links
  navMenuLinks[0].links.push(
    {
      name: intl.formatMessage(messages.home),
      key: 'left_nav_menu_home',
      ariaLabel: intl.formatMessage(messages.home),
      icon: 'Home',
      onClick: () => {
        history.push('/');
      },
      isSelected:
        window.location.href === window.location.protocol + '//' + window.location.host + '/' ? true : false,
    },
  );

  // creating page links for logged in user only
  if (isUserLoggedIn) {
    const menuItems = getLeftNavMenuItems(intl, history, isDevLogIn);
    menuItems.forEach((item) => navMenuLinks[0].links.push(item));

  }


  extensionsPages.forEach((page) => {
    if (page.showNavigation)
    {
      var ext = extensions.filter(x => x.pages.filter(y => y.key == page.key).length > 0);
      navMenuLinks[0].links.push(getNavObjectForPage(page as IAppExtensionPageExtended, ext.length > 0 ? ext[0] : null));
    }
  });
  
  return navMenuLinks;

};



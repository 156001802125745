import { createSelector } from 'reselect';
import { ReduxRootState, ReduxToDoListState, } from '..';
import { IToDo } from '../../models';
import { AxiosError } from 'axios';

const todoListState = (state: ReduxRootState): ReduxToDoListState => state.todoList;

export const getToDoList = createSelector(
  todoListState,
  (todoListState: ReduxToDoListState): IToDo[] => todoListState.todoList
);

export const getToDoListLoadingStatus = createSelector(
  todoListState,
  (todoListState: ReduxToDoListState): boolean => todoListState.isLoading
);

export const getToDoListError = createSelector(
  todoListState,
  (todoListState: ReduxToDoListState): AxiosError => todoListState.error
);


import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import {
  IStyle, Link, mergeStyleSets,
  SearchBox, FontWeights
} from 'office-ui-fabric-react';
import { FontSizes } from '@cseo/styles';
import { InjectedIntlProps } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { messages } from './Sample.messages';
import { AppRoutePath } from '../../../app/App.types';

export const HelpSampleLinkStyles = mergeStyleSets(
  {
    helpSampleLink: {
      margin: '8px 0',
      display: 'block',
      fontSize: FontSizes.size14,
      fontWeight: FontWeights.semibold
    } as IStyle
  }
);

interface OwnProps extends InjectedIntlProps {
  isAppReady: boolean;
}

type Props = OwnProps & RouteComponentProps;

const Help: (React.FC<Props>) = (props) => {
  const { history, intl } = props;

  useEffect(() => {
    // Placeholder
    // eslint-disable-next-line
  }, []);

  const handleNavigation = (routePath: string) => {
    history.push({ pathname: `${routePath}` });
  }

  const renderMain = (): JSX.Element => {
    return (
      <div>
        <SearchBox
          placeholder={intl.formatMessage(messages.helpPageSearchPlaceHolder)}
          onSearch={newValue => console.log('value is ' + newValue)}
          onFocus={() => console.log('onFocus called')}
          onBlur={() => console.log('onBlur called')}
          onChange={() => console.log('onChange called')}
        />

        <h2>{intl.formatMessage(messages.helpPageSubTile)}</h2>
        <Link href="#" onClick={() => handleNavigation(AppRoutePath.ModalBasicExample)} className={HelpSampleLinkStyles.helpSampleLink}>
          {intl.formatMessage(messages.helpPageLinkModalBasic)}
        </Link>
        <Link href="#" onClick={() => handleNavigation(AppRoutePath.DonutChartExample)} className={HelpSampleLinkStyles.helpSampleLink}>
          {intl.formatMessage(messages.helpPageLinkDonutChart)}
        </Link>
        <Link href="#" onClick={() => handleNavigation(AppRoutePath.TableExample)} className={HelpSampleLinkStyles.helpSampleLink}>
          {intl.formatMessage(messages.helpPageLinkTable)}
        </Link>
      </div>

    );
  }

  return renderMain();

}
export const HelpExample = withRouter(injectIntl(Help));


import React from 'react';
import ReactDOM from 'react-dom';
import { initializeIcons } from '@uifabric/icons';
import { AppSettingsProvider, AppServiceProvider, AppBaseProvider } from './core/provider'
import { App } from './app/App';
import { mergeStyles } from 'office-ui-fabric-react';
import './index.css';

import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './core/utils/AppInsights';

// Inject some global styles
mergeStyles({
    selectors: {
        ':global(body), :global(html), :global(#app)': {
            margin: 0,
            padding: 0,
            height: '100vh'
        }
    }
});

initializeIcons();

ReactDOM.render(
    <AppInsightsContext.Provider value={reactPlugin}>
        <AppServiceProvider>
            <AppBaseProvider>
                <AppSettingsProvider>
                    <App />
                </AppSettingsProvider>
            </AppBaseProvider>
        </AppServiceProvider>
    </AppInsightsContext.Provider>,
    document.getElementById('app'));

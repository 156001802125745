import { createSelector } from 'reselect';
import { ReduxRootState, ReduxDashboardVisualsConfigState } from '../..';
import { AxiosError } from 'axios';
import { IReportVisualProperties } from '../../../models/IReportVisualProperties'

const dashboardVisualsConfigState = (state: ReduxRootState): ReduxDashboardVisualsConfigState => state.dashboardVisualsConfig;

export const getdashboardVisualsConfig = createSelector(
    dashboardVisualsConfigState,
    (dashboardVisualsConfigState: ReduxDashboardVisualsConfigState): IReportVisualProperties[] => dashboardVisualsConfigState.DashboardVisualsConfig
  );
  
  export const getdashboardVisualsConfigLoadingStatus = createSelector(
    dashboardVisualsConfigState,
    (dashboardVisualsConfigState: ReduxDashboardVisualsConfigState): boolean => dashboardVisualsConfigState.isLoading
  );
  
  export const getdashboardVisualsConfigError = createSelector(
    dashboardVisualsConfigState,
    (dashboardVisualsConfigState: ReduxDashboardVisualsConfigState): AxiosError => dashboardVisualsConfigState.error
  );
  
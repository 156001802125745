
import React, { FC } from 'react';
import { Stack, IStackStyles, IStackTokens, IStackItemStyles } from 'office-ui-fabric-react';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
import { injectIntl, InjectedIntlProps } from 'react-intl';

interface OwnProps extends InjectedIntlProps {
  message: string;
}

type Props =  OwnProps & InjectedIntlProps;

const BusyIndicatorComponent: FC<Props> = (props) => {
  const { message } = props;
  const stackStyles: IStackStyles = {
    root: {
      width: '100%',
    },
  };
  const stackItemStyles: IStackItemStyles = {
    root: {
      padding: 5,
      width: '100%',
      alignSelf: 'center',
    },
  };

  const stackTokens: IStackTokens = {
    childrenGap: 20,
    padding: 20,
  };

  return (
    <Stack horizontal tokens={stackTokens} styles={stackStyles} >
      <Stack.Item styles={stackItemStyles}>
        <Spinner label={message} ariaLive="assertive" labelPosition="top" />
      </Stack.Item>
    </Stack>
  );
};

export const BusyIndicator = injectIntl(BusyIndicatorComponent);
import { appConfig } from '../../../app/App.config';

export const getConfig = () => {
  const config = appConfig.extensionConfig;
  Object.assign(config, { IsMockData: appConfig.isMockData });
  // Bellow attributes added for demo purpose only to support Supplier extension. 
  // Please remove during actual implementation
  Object.assign(config, { API_ENDPOINT: '' });
  Object.assign(config, { INVOICE_API_ENDPOINT: '' });
  Object.assign(config, { USERPROFILE_API_ENDPOINT: '' });
  Object.assign(config, { USERMANAGEMENT_API_ENDPOINT: '' });
  Object.assign(config, { INVOICE_RESOURCE_ID: '' });
  Object.assign(config, { INVOICE_RESOURCE_ID_MSA: '' });
  Object.assign(config, { OCP_APIM_SUBSCRIPTION_KEY: '' });
  return config;
};
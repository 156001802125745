import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  pageTile: {
    id: 'toDoListPage.title',
    defaultMessage: 'To Do List'
  },
  loadingData: {
    id: 'toDoListPage.loadingData',
    defaultMessage: 'Loading To Do list, please wait...'
  },
  noDataFound: {
    id: 'toDoListPage.noDataFound',
    defaultMessage: 'Currently, there are no To Do availiable.',
  },
  tableTitle: {
    id: 'toDoListPage.tableTitle',
    defaultMessage: 'Showing All To Do: ',
  },
  colId: {
    id: 'toDoListPage.colId',
    defaultMessage: 'Action'
  },
  colTitle: {
    id: 'toDoListPage.colTitle',
    defaultMessage: 'Title'
  },
  colOwner: {
    id: 'toDoListPage.colOwner',
    defaultMessage: 'Owner'
  },
  colTask: {
    id: 'toDoListPage.colTask',
    defaultMessage: 'Task'
  },
  addNewButtonText: {
    id: 'toDoListPage.addNewButtonText',
    defaultMessage: 'Add new task'
  },
  addTaskTitle: {
    id: 'toDoListPage.addTaskTitle',
    defaultMessage: 'Add task'
  },
  editTaskTitle: {
    id: 'toDoListPage.editTaskTitle',
    defaultMessage: 'Edit task'
  },
  updateFormSubmitButtonText: {
    id: 'toDoListPage.updateFormSubmitButtonText',
    defaultMessage: 'Submit'
  },
  modalCloseTitle: {
    id: 'toDoListPage.modalCloseTitle',
    defaultMessage: 'Close modal window'
  },
  exportToCSV: {
    id: 'invoiceTable.exportToCSV',
    defaultMessage: 'Export To CSV',
  },
});


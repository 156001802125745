
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { IAppExtensionPage } from '@msx/platform-services';
import { Extension } from '../core/components'
import { AppRoutePath } from './App.types';
import {
  Home,
  Dashboard,
  ToDoList,
  NotFound,
  AdminPage
} from './pages'

import {
  ModalBasicExample,
  DonutChartExample,
  TableExample
} from './components/sample'

export interface Props {
  extensonPages: IAppExtensionPage[];
}

export const Routes: (React.FC<Props>) = (props) => {
  const { extensonPages } = props;

  const renderMain = (): JSX.Element => {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/null" component={Home} />

        {/* Create app specific routes */}
        <Route path={AppRoutePath.AdminPage} component={AdminPage} />
        <Route path={AppRoutePath.Dashboard} component={Dashboard} />
        <Route path={AppRoutePath.ToDoList} component={ToDoList} />
        <Route path={AppRoutePath.ModalBasicExample} component={ModalBasicExample} />
        <Route path={AppRoutePath.DonutChartExample} component={DonutChartExample} />
        <Route path={AppRoutePath.TableExample} component={TableExample} />

        {/* Create route for dynamic extensions       */}
        {extensonPages.map((page) => {
          return <Route
            key={page.name}
            name={page.name}
            path={'/' + page.routePath}
            render={(): React.ReactElement => <Extension componentKey={page.key} />}
          />;
        })}

        {/* Create not found pages route */}
        <Route component={NotFound} />
      </Switch>
    );
  }

  return renderMain();

}



import { IAppTheme } from '@msx/platform-services'

export const getStyles = (theme: IAppTheme) => {
  return {
    headingMain: {
      margin: '0',
    },
    actionButtonLink: {
      height: '80%',
      fontSize: '12px',
    },
    deleteButtonLink: {
    },
  };
};

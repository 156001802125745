import moment from 'moment';
import { NotificationListItem } from '@cseo/controls';

export const initialNotifications: NotificationListItem[] = [
  {
    itemKey: '1',
    displayStatus: 'new',
    status: 'unread',
    timeDelivered: moment().add(-1, 'hours'),
    messageBodyText: 'Need help! Have time to review my updates before the review',
    subjectIcon: 'Mail',
    subjectHeader: 'Lorem ipsum 1',
    senderName: 'Coulson D\'Avanzo',
    actionRequiredText: 'Requested action',
    actionRequiredLink: 'http://www.bing.com'
  },
  {
    itemKey: '2',
    displayStatus: 'new',
    status: 'unread',
    timeDelivered: moment().add(-117, 'minutes'),
    messageBodyText: 'Can you take a look at my designs before the review',
    subjectIcon: 'Mail',
    subjectHeader: 'Lorem ipsum 2',
    senderName: 'Joe Cool',
    senderImageUrl: 'https://pbs.twimg.com/profile_images/497043545505947648/ESngUXG0.jpeg'
  },
  {
    itemKey: '3',
    displayStatus: 'new',
    status: 'unread',
    timeDelivered: moment().add(-120, 'minutes'),
    subjectIcon: 'Money',
    subjectHeader: 'Lorem ipsum 2',
    senderName: 'Abc ZZZZ',
    actionRequiredText: 'Requested action',
    actionRequiredLink: 'http://www.bing.com'
  },
  {
    itemKey: '4',
    displayStatus: 'new',
    status: 'unread',
    timeDelivered: moment().add(-22, 'hours'),
    messageBodyText: 'The quick brown fox jumps over the lazy dog',
    subjectIcon: 'MapPin',
    subjectHeader: 'Lorem ipsum 3',
    actionRequiredText: 'Requested action',
    actionRequiredLink: 'http://www.bing.com'
  },
  {
    itemKey: '5',
    displayStatus: 'old',
    status: 'unread',
    timeDelivered: moment().add(-31, 'hours'),
    subjectIcon: 'Mail',
    subjectHeader: 'Lorem ipsum 4',
    eventTimeBlock: '09:00 AM - 01:00 PM',
    eventLocation: 'Millennium C / 24',
    actionRequiredText: 'Requested action',
    actionRequiredLink: 'http://www.bing.com'
  },
  {
    itemKey: '6',
    displayStatus: 'new',
    status: 'unread',
    timeDelivered: moment('9/10/19'),
    messageBodyText: 'The quick brown fox jumps over the lazy dog',
    subjectIcon: 'Mail',
    subjectHeader: 'Lorem ipsum 3'
  }
];
import { AnimationStyles, FontWeights, getTheme, IButtonStyles, IIconProps, IStackProps, IStackStyles, IStackTokens, mergeStyleSets } from "office-ui-fabric-react";

const theme = getTheme();
export const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    height: '70%',
    width: '35%',
    padding: '20px'
  },
  body:{
    height: '100%'
  }
});

export const gapStackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 2,
};

export const stackStyles: IStackStyles = {
  root: {
    minHeight: '90%',
    minWidth: '90%'
  },
};
import React, { useState, useEffect } from 'react';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { PrimaryButton } from 'office-ui-fabric-react';
import { Stack, IStackProps, IStackStyles } from 'office-ui-fabric-react/lib/Stack';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { IToDo } from '../../models';
import { messages } from './ToDoList.messages';


interface OwnProps extends InjectedIntlProps {
  toDo: IToDo;
  onSubmitClick?: (todo: IToDo) => void;
}

type ToDoListProps = OwnProps & InjectedIntlProps;

const UpdateDoComponent: React.FC<ToDoListProps> = props => {
  const { toDo, intl } = props;
  const [title, setTitle] = useState<string>(toDo ? toDo.title : '');
  const emailAddress = `${'testUser@microsoft.com'}`
  const [owner] = useState<string>(toDo ? toDo.owner : emailAddress);

  useEffect(() => {
    //TODO:
    // eslint-disable-next-line
  }, []);


  const handleSubmitClick = () => {
    const newToDo: IToDo = {
      id: toDo ? toDo.id : -1,
      title: title, owner: owner,
      state: toDo ? toDo.state : 'New',
    }
    props.onSubmitClick(newToDo)
  }

  const renderMain = (): JSX.Element => {
    const stackTokens = { childrenGap: 50 };
    const stackStyles: Partial<IStackStyles> = { root: { marginBottom: 20 } };
    const columnProps: Partial<IStackProps> = {
      tokens: { childrenGap: 15 },
      styles: { root: { width: 300 } },
    };

    const onTitleChange = (event: React.FormEvent<HTMLInputElement>, newValue?: string) => {
      setTitle(newValue);
    }

    return (
      <div>
        <Stack horizontal tokens={stackTokens} styles={stackStyles}>
          <Stack {...columnProps}>
            <TextField label={intl.formatMessage(messages.colTask)} defaultValue={title} onChange={onTitleChange} required />
            <TextField label={intl.formatMessage(messages.colOwner)} defaultValue={owner} />
          </Stack>
        </Stack>
        <PrimaryButton text={intl.formatMessage(messages.updateFormSubmitButtonText)} onClick={handleSubmitClick} />
      </div>
    )
  }
  return renderMain();
}

export const UpdateToDo = injectIntl(UpdateDoComponent);

import {IPortalConfig} from '@msx/platform-services'
export const HTTP_STATUS_NOT_FOUND = 404;

export interface IAppConfig extends IPortalConfig {
}

export const AppRoutePath = {
  AdminPage: '/AdminPage',
  Dashboard: '/Dashboard',
  Profile: '/Profile',
  ToDoList: '/ToDoList',
  ModalBasicExample: '/ModalBasicExample',
  DonutChartExample: '/DonutChartExample',
  TableExample: '/TableExample',
}


import { createSelector } from 'reselect';
import { ReduxRootState, ReduxToDoCrudState, } from '..';
import { AxiosError } from 'axios';

const todoCrudState = (state: ReduxRootState): ReduxToDoCrudState => state.todoCrud;

export const getToDoCrudLoadingStatus = createSelector(
  todoCrudState,
  (todoCrudState: ReduxToDoCrudState): boolean => todoCrudState.isLoading
);

export const getToDoCrudError = createSelector(
  todoCrudState,
  (todoCrudState: ReduxToDoCrudState): AxiosError => todoCrudState.error
);


import React, { useContext, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { InjectedIntlProps } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Table } from '@msx/react-ui-component'
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
import { getStyles } from './Sample.styles'
import { ApplicationContext } from '@msx/platform-services'
import { messages } from './Sample.messages';

interface OwnProps extends InjectedIntlProps {
  //TODO: any
}

type Props = OwnProps & RouteComponentProps;

const getClassNames = classNamesFunction<any, any>();
let classes: any;

const TestTable: (React.FC<Props>) = (props) => {
  const { intl } = props;
  const { appState } = useContext(ApplicationContext);
  classes = getClassNames(getStyles, appState.theme);

  useEffect(() => {
    // Placeholder
    // eslint-disable-next-line
  }, []);
  const columns = [
    {
      key: 'lineId',
      fieldName: 'lineId',
      name: 'Line \n Items',
    },
    {
      key: 'description',
      fieldName: 'description',
      name: 'Microsoft \n Doc number',
      minWidth: 200,
      maxWidth: 250,
    },
    {
      key: 'quantity',
      fieldName: 'quantity',
      name: 'Item \n Quantity',
      onRender: (row) => {
        return (
          (row.quantity) ?
            <div className='number-column-large'>{row.quantity}  </div> : <div></div>
        )
      },
      headerClassName: 'number-column-large-heading',
      minWidth: 75,
      maxWidth: 100,
    },
  ];

  const rows = [
    {
      lineId: '1',
      description: 'description 1',
      quantity: 100,
    },
    {
      lineId: '2',
      description: 'description 2',
      quantity: 200,
    },
    {
      lineId: '3',
      description: 'description 3',
      quantity: 300,
    },
    {
      lineId: '4',
      description: 'description 1',
      quantity: 100,
    },
    {
      lineId: '5',
      description: 'description 2',
      quantity: 200,
    },
    {
      lineId: '6',
      description: 'description 3',
      quantity: 300,
    },
    {
      lineId: '7',
      description: 'description 1',
      quantity: 100,
    },
    {
      lineId: '8',
      description: 'description 2',
      quantity: 200,
    },
    {
      lineId: '9',
      description: 'description 3',
      quantity: 300,
    },
    {
      lineId: '10',
      description: 'description 1',
      quantity: 100,
    },
    {
      lineId: '11',
      description: 'description 2',
      quantity: 200,
    },
    {
      lineId: '12',
      description: 'description 3',
      quantity: 300,
    },
  ]
  const csvHeaders = [
    { label: 'Sl No', key: 'lineId' },
    { label: 'Item description', key: 'description' },
    { label: 'quantity', key: 'quantity' },
  ];

  const csvTelemetryProps = {
    supplierId: 'S001',
    currentCompanyCode: 'C001',
    url: window.location.href
  }
  const exportCsvProps = {
    canExportCsv: true,
    csvFilename: 'Payments.csv',
    csvHeaders: csvHeaders,
    csvTelemetryProps: csvTelemetryProps,
    id: 'PaymentExportToCsv',
    exportButtonLabel: 'Export to CSV'
  }

  const renderMain = (): JSX.Element => {
    return (
      <div className={classes.root}>
        <h1>{intl.formatMessage(messages.helpPageLinkTable)}</h1>
        <Table
          isLoading={false}
          columns={columns}
          rows={rows}
          tableTitle='Invoices'
          exportCsvProps={exportCsvProps}
          theme={appState.theme}
        />
      </div>
    );
  }

  return renderMain();

}

export const TableExample = withRouter(injectIntl(TestTable));



import { AxiosError } from 'axios';
import { IHttpClient, IHttpClientRequest, IPortalConfig } from '@msx/platform-services';
import { IToDo } from '../../models';
import {
  CRUD_TODO_LIST_BEGIN,
  CRUD_TODO_LIST_SUCCESS,
  CRUD_TODO_LIST_FAILURE,
} from '..';


export const crudToDoListBegin = () => ({
  type: CRUD_TODO_LIST_BEGIN
});

export const crudToDoListSuccess = (payload: IToDo) => ({
  type: CRUD_TODO_LIST_SUCCESS,
  payload: payload
});

export const crudToDoListError = (error: AxiosError) => ({
  type: CRUD_TODO_LIST_FAILURE,
  payload: error
});


export const addToDo = (httpClient: IHttpClient, todo: IToDo, appConfig:IPortalConfig) => {
  return async (dispatch: any) => {
    dispatch(crudToDoListBegin());
    try {
      const url = `${appConfig.apiConfig.baseUrl}TodoList`;
      const newTodo = {
        ...todo,
        title: todo.title + '|' + todo.state
      }
      const request: IHttpClientRequest = {
        resource: appConfig.apiConfig.resource,
        data: newTodo,
      };
      const response: any = await httpClient.post(url, request);
      dispatch(crudToDoListSuccess(response.data ? response.data : null));
    } catch (err) {
      dispatch(crudToDoListError(err));
    }
  }
}

export const editToDo = (httpClient: IHttpClient, todo: IToDo, appConfig:IPortalConfig) => {
  return async (dispatch: any) => {
    dispatch(crudToDoListBegin());
    try {
      const url = `${appConfig.apiConfig.baseUrl}TodoList/${todo.id}`;
      const newTodo = {
        ...todo,
        title: todo.title + '|' + todo.state
      }
      const request: IHttpClientRequest = {
        resource: appConfig.apiConfig.resource,
        data: newTodo,
      };
      const response: any = await httpClient.patch(url, request);
      dispatch(crudToDoListSuccess(response.data ? response.data : null));
    } catch (err) {
      dispatch(crudToDoListError(err));
    }
  }
}

export const deleteToDo = (httpClient: IHttpClient, todo: IToDo, appConfig:IPortalConfig) => {
  return async (dispatch: any) => {
    dispatch(crudToDoListBegin());
    try {
      const url = `${appConfig.apiConfig.baseUrl}TodoList/${todo.id}`;
      const request: IHttpClientRequest = {
        resource: appConfig.apiConfig.resource,
      };
      const response: any = await httpClient.delete(url, request);
      dispatch(crudToDoListSuccess(response.data ? response.data : null));
    } catch (err) {
      dispatch(crudToDoListError(err));
    }
  }
}

import { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { IHttpClient, IHttpClientRequest, IPortalConfig } from '@msx/platform-services';
import {
  FETCH_EXTENSIONMETADATA_LIST_BEGIN,
  FETCH_EXTENSIONMETADATA_LIST_SUCCESS,
  FETCH_EXTENSIONMETADATA_LIST_FAILURE,
} from '../..';
import { IExtensionMetadata } from '../../../models/IExtensionMetadata';
import { trackPromise } from 'react-promise-tracker';

export const fetchExtensionMetadataListBegin = () => ({
  type: FETCH_EXTENSIONMETADATA_LIST_BEGIN
});

export const fetchExtensionMetadataListSuccess = (payload: IExtensionMetadata[]) => ({
  type: FETCH_EXTENSIONMETADATA_LIST_SUCCESS,
  payload: payload
});

export const fetchExtensionMetadataListError = (error: AxiosError) => ({
  type: FETCH_EXTENSIONMETADATA_LIST_FAILURE,
  payload: error
});

export const fetchExtensionMetadataList = (httpClient: IHttpClient, appConfig:IPortalConfig) => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchExtensionMetadataListBegin());
    try {
      const resource = appConfig.registrationConfig.resource;
      const url = `${appConfig.registrationConfig.apiEndpoint}/List`;
      const request: IHttpClientRequest = {
        resource: resource,
      };
      const response: any = await trackPromise(httpClient.get(url, request));
      dispatch(fetchExtensionMetadataListSuccess(response.data ? processExtensionMetadataList(response.data) : null));
    } catch (err) {
      dispatch(fetchExtensionMetadataListError(err));
    }
  }
}

const processExtensionMetadataList = (extensionList) => {
    extensionList.forEach((item) => {
        if(typeof item['isActive'] == "boolean"){
            switch(item['isActive']){
                case true:
                    item['isActive'] = "True"
                    break;
                case false:
                    item['isActive'] = "False"
                    break;
            }
        }
      });
    return extensionList;
}
import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  helpPageTile: {
    id: 'helpPage.Title',
    defaultMessage: 'Help'
  },
  helpPageSubTile: {
    id: 'helpPage.helpPageSubTile',
    defaultMessage: 'Featured help'
  },
  helpPageSearchPlaceHolder: {
    id: 'helpPage.helpPageSearchPlaceHolder',
    defaultMessage: 'Search help'
  },
  helpPageLinkAccessToken: {
    id: 'helpPage.helpPageLinkAccessToken',
    defaultMessage: 'Acquire an access token'
  },
  helpPageLinkModalBasic: {
    id: 'helpPage.helpPageLinkModalBasic',
    defaultMessage: 'Modal'
  },
  helpPageLinkDonutChart: {
    id: 'helpPage.helpPageLinkDonutChart',
    defaultMessage: 'MSX Donut Chart'
  },
  helpPageLinkTable: {
    id: 'helpPage.helpPageLinkTable',
    defaultMessage: 'MSX Table'
  },
  settingsPageLinkGeneral: {
    id: 'helpPage.settingsPageLinkGeneral',
    defaultMessage: 'General'
  },
  settingsPageLinkNotifications: {
    id: 'helpPage.settingsPageLinkNotifications',
    defaultMessage: 'Notifications'
  },
  settingsPageLinkSounds: {
    id: 'helpPage.settingsPageLinkSounds',
    defaultMessage: 'Sounds'
  },
  settingsPageLinkFavorites: {
    id: 'helpPage.settingsPageLinkFavorites',
    defaultMessage: 'Favorites'
  },
  settingsPageLinkAccessibility: {
    id: 'helpPage.settingsPageLinkAccessibility',
    defaultMessage: 'Accessibility'
  },
  settingsPageLinkFabricComponent: {
    id: 'helpPage.settingsPageLinkFabricComponent',
    defaultMessage: 'show fabric components'
  },
  about1: {
    id: 'helpPage.about1',
    defaultMessage: "This portal is based on a UI composition system whose primary design goal is to enable the integration of UI built by several teams into a single, robust single-page web application.",
  },
  about2: {
    id: 'helpPage.about2',
    defaultMessage: "With this system, a team develops a UI extension to plug into and extend the UI of the portal. Teams develop and refine UI iteratively and can choose a deployment cadence that suits their team schedule  and their customer needs. They can safely link their UI with another UI constructed by other teams, resulting in a portal application that (to the portal end user) appears to have been built by a single team.    Any bug in a team's UI has only a local impact on that team's UI and does not impact the    availability/reliability of the larger portal UX or that of any other UI extension.",
  },
  about3: {
    id: 'helpPage.about3',
    defaultMessage: "The portal platform allows extensions that are built as web component. For any queries please reach out to MSX framework team.",
  },
  featureTitle: {
    id: 'helpPage.featureTitle',
    defaultMessage: "What features are available in this framework?",
  },
  advanceFeatureTitle: {
    id: 'helpPage.advanceFeatureTitle',
    defaultMessage: "Advanced features",
  },
  feature1: {
    id: 'helpPage.feature1',
    defaultMessage: "Authentication service (ADAL, MSAL, MSAL v2)",
  },
  feature2: {
    id: 'helpPage.feature2',
    defaultMessage: "Telemetry client",
  },
  feature3: {
    id: 'helpPage.feature3',
    defaultMessage: "http client",
  },
  feature4: {
    id: 'helpPage.feature4',
    defaultMessage: "Internationalization with React-Intl",
  },
  feature5: {
    id: 'helpPage.feature5',
    defaultMessage: "React Router (page navigation)",
  },
  feature6: {
    id: 'helpPage.feature6',
    defaultMessage: "React hook",
  },
  feature7: {
    id: 'helpPage.feature7',
    defaultMessage: "Fluent UI controls",
  },
  feature8: {
    id: 'helpPage.feature8',
    defaultMessage: "CSEO Coherence Controls (Left Navigation, Header)",
  },
  feature9: {
    id: 'helpPage.feature9',
    defaultMessage: "OCV integration",
  },
  feature10: {
    id: 'helpPage.feature10',
    defaultMessage: "State management with Redux",
  },
  feature11: {
    id: 'helpPage.feature11',
    defaultMessage: "Finance BOT integration",
  },
  feature12: {
    id: 'helpPage.feature12',
    defaultMessage: "UX Theme integration",
  },
  feature13: {
    id: 'helpPage.feature13',
    defaultMessage: "Dashboard customization",
  },
  feature14: {
    id: 'helpPage.feature14',
    defaultMessage: "Portal extension builder",
  },
  feature15: {
    id: 'helpPage.feature15',
    defaultMessage: "Onboard new portal extension developed by different teams",
  },
  dashboard1: {
    id: 'helpPage.dashboard1',
    defaultMessage: "Dashboards are a focused and organized view of your business features in the portal. Use dashboards as a workspace where you can quickly launch tasks for day-to-day operations and monitor the success of business. Build custom dashboards based on projects, tasks, or user roles, for example.",
  },
  dashboard2: {
    id: 'helpPage.dashboard2',
    defaultMessage: "MSX portal provides a default dashboard in the home page as a starting point. You can customize dashboards here. Please ensure that administrator has enabled require extension tiles for your project.",
  },

});


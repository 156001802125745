import { AnyAction } from 'redux';
import {
  SAVE_DASHBOARD_VISUALS_CONFIG_BEGIN,
  SAVE_DASHBOARD_VISUALS_CONFIG_SUCCESS,
  SAVE_DASHBOARD_VISUALS_CONFIG_FAILURE,
  ReduxDashboardVisualsConfigSaveState
} from '../..';
 

const dashboardVisualsConfigSaveInitialState:   ReduxDashboardVisualsConfigSaveState = {
  isLoading: true,
  error: null,
};

export default function dashboardVisualsConfigSaveReducer(
  state: ReduxDashboardVisualsConfigSaveState = dashboardVisualsConfigSaveInitialState,
  action: AnyAction
) {
  switch (action.type) {
    case SAVE_DASHBOARD_VISUALS_CONFIG_BEGIN:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case SAVE_DASHBOARD_VISUALS_CONFIG_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case SAVE_DASHBOARD_VISUALS_CONFIG_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
}

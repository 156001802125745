import { createSelector } from 'reselect';
import { ReduxRootState, ReduxDashboardVisualsConfigSaveState } from '../..';
import { AxiosError } from 'axios';
import { IReportVisualProperties } from '../../../models/IReportVisualProperties'

const dashboardVisualsConfigSaveState = (state: ReduxRootState): ReduxDashboardVisualsConfigSaveState => state.dashboardVisualsConfigSave;

  export const getdashboardVisualsConfigSaveLoadingStatus = createSelector(
    dashboardVisualsConfigSaveState,
    (dashboardVisualsConfigSaveState: ReduxDashboardVisualsConfigSaveState): boolean => dashboardVisualsConfigSaveState.isLoading
  );
  
  export const getdashboardVisualsConfigSaveError = createSelector(
    dashboardVisualsConfigSaveState,
    (dashboardVisualsConfigSaveState: ReduxDashboardVisualsConfigSaveState): AxiosError => dashboardVisualsConfigSaveState.error
  );
  
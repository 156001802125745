import { AnyAction } from 'redux';
import {
  EXTENSION_METADATA_JSON_UPLOAD_SUCCESS,
  EXTENSION_METADATA_JSON_UPLOAD_BEGIN,
  EXTENSION_METADATA_JSON_UPLOAD_FAILURE,
  ReduxExtensionMetadataJsonUploadState
} from '../..';

const extensionMetadataJsonUploadInitialState: ReduxExtensionMetadataJsonUploadState = {
    isLoading: true,
    error: null,
};
  
export default function extensionMetadataJsonUploadReducer(
    state: ReduxExtensionMetadataJsonUploadState = extensionMetadataJsonUploadInitialState,
    action: AnyAction
) {
    switch (action.type) {
        case EXTENSION_METADATA_JSON_UPLOAD_BEGIN:
          return {
            ...state,
            isLoading: true,
            error: null,
          };
        case EXTENSION_METADATA_JSON_UPLOAD_SUCCESS:
          return {
            ...state,
            isLoading: false,
          };
        case EXTENSION_METADATA_JSON_UPLOAD_FAILURE:
          return {
            ...state,
            isLoading: false,
            error: action.payload
          };
        default:
          return state;
    }
}
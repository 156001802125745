import React, { useContext, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { InjectedIntlProps } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { MsxCard, MsxDonutChart } from '@msx/react-ui-component'
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
import { getStyles } from './Sample.styles'
import { ApplicationContext } from '@msx/platform-services'
import { messages } from './Sample.messages';

interface OwnProps extends InjectedIntlProps {
  //if: any
}

type Props = OwnProps & RouteComponentProps;

const getClassNames = classNamesFunction<any, any>();
let classes: any;

const DonutChart: (React.FC<Props>) = (props) => {
  const { intl } = props;
  const { appState } = useContext(ApplicationContext);
  classes = getClassNames(getStyles, appState.theme);

  useEffect(() => {
    // Placeholder
    // eslint-disable-next-line
  }, []);

  const cardConfig = {
    cardTitle: 'Purchase Order (tile layout)',
    minHeight: '230px',
    minWidth: '375px',
  }

  const getPoChartData = () => {
    return ([
      {
        name: "Paid",
        value: 9800000,
        color: "rgb(92, 46, 145)",
      },
      {
        name: "Not Paid",
        value: 9800000,
        color: "rgb(49, 209, 219)",
      },
    ]);
  }
  const handleGraphClick = (filter) => {
    alert('you have selected filter: ' + filter);
  }

  const handleExpandClick = () => {
    alert('you clicked on expand buttton');
  }

  const renderCard = () => {
    return <MsxDonutChart
      id="PurchaseOrderTileDonutChart"
      title="chart tile layout"
      selectedIndex={-1}
      chartData={getPoChartData()}
      total={1250000}
      handleGraphClick={handleGraphClick}
      currency={"USD"}
      slimLayout={false}
      theme={appState.theme}
    />
  }
  const renderSlimCard = () => {
    return <MsxDonutChart
      id="SlimPurchaseOrderTileDonutChart"
      title="chart slim layout"
      selectedIndex={-1}
      chartData={getPoChartData()}
      total={1250000}
      handleGraphClick={handleGraphClick}
      currency={"USD"}
      slimLayout={true}
      theme={appState.theme}
    />
  }

  const renderMain = (): JSX.Element => {
    return (
      <div className={classes.root}>
         <h1>{intl.formatMessage(messages.helpPageLinkDonutChart)}</h1>
        <div className={classes.purchaseOrderTileContainer}>
          <MsxCard
            cardFrameContent={cardConfig}
            loading={false}
            onExpandClick={handleExpandClick}
            id='PurchaseOrderCard'
          >
            {renderCard()}
          </MsxCard>
        </div>
        <div className={classes.slimPurchaseOrderTileContainer}>
          <h3>Purchase Order (row layout)</h3>
          {renderSlimCard()}
        </div>
      </div>
    );
  }

  return renderMain();

}

export const DonutChartExample = withRouter(injectIntl(DonutChart));


import { IAppTheme } from '@msx/platform-services'

export const getStyles = (theme: IAppTheme) => {
  return {
    root: {
      padding: '20px',
    },
    headingMain: {
      marginLeft: '20px',
    },
    purchaseOrderTileContainer: {
      width: '375px',
      height: '230px'
    },
    slimPurchaseOrderTileContainer: {
      marginTop: '40px',
    },
    aboutroot: {
      maxWidth: '1024px',
    },
  }
};


